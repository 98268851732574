var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.$store.getters["group/groups"],
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["group/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["group/isLoading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["group/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push("/rooms/groups/" + item.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("groupsList.title"),
                    cta: _vm.$store.getters["session/isAdmin"]
                      ? _vm.$t("common.group")
                      : ""
                  },
                  on: { ctaClick: _vm.onCreate },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.actions",
            fn: function() {
              return [_c("va-details-button")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "va-dialog", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "va-card",
            [
              _c("div", { staticClass: "va-title" }, [
                _vm._v(_vm._s(_vm.$t("groupsList.newTitle")))
              ]),
              _c(
                "v-form",
                {
                  ref: "newGroupForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onSave },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.name")
                    },
                    model: {
                      value: _vm.group.name,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "name", $$v)
                      },
                      expression: "group.name"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.description")
                    },
                    model: {
                      value: _vm.group.description,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "description", $$v)
                      },
                      expression: "group.description"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("va-button", {
                        staticClass: "mr-4",
                        attrs: {
                          color: "secondary",
                          type: "outline",
                          loading: _vm.$store.getters["group/isLoading"],
                          cta: _vm.$t("common.dismiss")
                        },
                        on: {
                          click: function($event) {
                            _vm.dialog = false
                          }
                        }
                      }),
                      _c("va-button", {
                        attrs: {
                          submit: "",
                          disabled: !_vm.valid,
                          loading: _vm.$store.getters["group/isLoading"],
                          cta: _vm.$t("common.submit")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }