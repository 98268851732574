






































































import Vue, { PropType } from "vue";
import { GroupedPolicy } from "@/models/policy";
import RoomService from "@/services/roomService";
import { RoomDetail } from "@/models/room";
import GroupService from "@/services/groupService";
import cloneDeep from "lodash/cloneDeep";
import VaIcon from "@/components/atoms/VaIcon.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  name: "PoliciesChooser",
  components: { VaNotification, VaButton, VaIcon },
  props: {
    policies: {
      required: true,
      type: Array as PropType<Array<GroupedPolicy>>,
    },
    groupId: {
      required: false,
      type: String,
    },
    isRoom: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideIcons: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.updateGroupedPolices();
    this.$store.commit("policy/setChanged", false);
  },
  beforeDestroy() {
    this.$store.commit("policy/setChanged", false);
  },
  watch: {
    policies: function () {
      this.updateGroupedPolices();
    },
  },
  data() {
    return {
      processing: 0,
      processingAll: false,
      model: [] as Array<{ value: string | null; isIn: boolean | null }>,
      groupedPolicies: [] as Array<GroupedPolicy>,
      currentPolicyId: "",
    };
  },
  methods: {
    onFocus(index: number): void {
      this.currentPolicyId = this.model[index].value as string;
    },
    onChange(policyId: string, groupId: number, index: number) {
      this.$store.commit("policy/setChanged", true);
      if (this.isRoom) {
        this.processingPolicyOnRoom(policyId, groupId, index);
      } else {
        this.processingPolicyOnGroup(policyId, groupId);
      }
    },
    async processingPolicyOnRoom(
      policyId: string,
      groupId: number,
      index: number
    ) {
      this.processing = groupId;
      try {
        if (policyId) {
          this.model[index].isIn = await RoomService.applyPolicy(
            this.room.id,
            policyId
          );
        } else {
          this.model[index].isIn = await RoomService.removePolicy(
            this.room.id,
            this.currentPolicyId
          );
          this.$store.commit("notifications/displayNotification", {
            message: this.$t("common.policyRemoved"),
            type: "success",
          });
        }
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: e,
          type: "error",
        });
        this.$sentry.capture(e, "PoliciesChooser", "processingPolicyOnRoom");
        // maybe set old value
      } finally {
        this.processing = 0;
      }
    },
    async processingPolicyOnGroup(policyId: string, groupId: number) {
      this.processing = groupId;
      try {
        if (policyId) {
          await GroupService.applyPolicy(this.groupId, policyId);
          this.$store.commit("notifications/displayNotification", {
            message: this.$t("common.policyApplied"),
            type: "success",
          });
        } else {
          await GroupService.removePolicy(this.groupId, this.currentPolicyId);
          this.$store.commit("notifications/displayNotification", {
            message: this.$t("common.policyRemoved"),
            type: "success",
          });
        }
        this.$store.dispatch("group/fetchGroup", this.groupId);
      } catch (e) {
        this.$store.commit("notifications/displayNotification", {
          message: e,
          type: "error",
        });
        this.$sentry.capture(e, "PoliciesChooser", "processingPolicyOnGroup");
        // maybe set old value
      } finally {
        this.processing = 0;
      }
    },
    async applyAll() {
      this.$store.commit("policy/setChanged", true);
      this.processingAll = true;
      try {
        await RoomService.applyAllPolicies(this.room.id);
        this.$store.commit("notifications/displayNotification", {
          message: this.$t("common.policyApplied"),
          type: "success",
        });
        this.$emit("applied");
      } catch (err) {
        const msg = err.response?.data?.message
          ? err.response?.data?.message
          : err.message;
        this.$store.commit("notifications/displayNotification", {
          message: msg,
          type: "error",
        });
        this.$sentry.capture(err, "PoliciesChooser", "applyAll");
      } finally {
        this.processingAll = false;
      }
    },
    updateGroupedPolices() {
      //TODO bad patch hide avConference policy
      this.groupedPolicies = cloneDeep(this.policies).filter((p) => {
        return p.groupName !== "AV Conference";
      });
      this.model = [];
      this.groupedPolicies.forEach((groupedPolicy: GroupedPolicy, index) => {
        // this.groupedPolicies[index].groupPolicies.sort((a, b) =>
        //   a.name.toLowerCase() > b.name.toLowerCase()
        //     ? 1
        //     : b.name.toLowerCase() > a.name.toLowerCase()
        //     ? -1
        //     : 0
        // );
        this.model.push({
          value: groupedPolicy.selectedPolicyId
            ? groupedPolicy.selectedPolicyId
            : "",
          isIn: groupedPolicy.isInPolicy,
        });
        if (this.isRoom) {
          if (this.groupedPolicies[index].selectedGroupPolicyId) {
            let inheritedPolicy = this.groupedPolicies[
              index
            ].groupPolicies.find((policy) => {
              return (
                this.groupedPolicies[index].selectedGroupPolicyId ===
                policy.policyId
              );
            });

            // recupero la policy dalla lista, le cambio il nome/valore e la metto in testa
            this.groupedPolicies[index].groupPolicies.unshift({
              policyId: "",
              name:
                this.$t("common.inherit") + " (" + inheritedPolicy?.name + ")",
            });
          } else {
            this.groupedPolicies[index].groupPolicies.unshift({
              policyId: "",
              name: this.$t("common.none") as string,
            });
          }
        } else {
          this.groupedPolicies[index].groupPolicies.unshift({
            policyId: "",
            name: this.$t("common.none") as string,
          });
        }
      });
    },
  },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
    showAlert(): boolean {
      return this.model.some((el) => {
        return el.isIn === false;
      });
    },
  },
});
