import { MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";

export interface AnalyticsState {
  startDate: number | undefined;
  endDate: number | undefined;
}

// type AnalyticsContext = ActionContext<AnalyticsState, RootState>;

export const namespaced = true;

export const state = (): AnalyticsState => ({
  startDate: Date.now() - 1000 * 60 * 60 * 24,
  endDate: Date.now(),
});

export const getters: GetterTree<AnalyticsState, RootState> = {
  startDate: (state) => {
    return state.startDate;
  },
  endDate: (state) => {
    return state.endDate;
  },
};

export const mutations: MutationTree<AnalyticsState> = {
  setCurrentRange(
    state: AnalyticsState,
    dates: { start: number | undefined; end: number | undefined }
  ) {
    if (dates.start && dates.end && dates.start > dates.end) {
      //order dates
      state.startDate = dates.end;
      state.endDate = dates.start;
    } else {
      state.startDate = dates.start;
      state.endDate = dates.end;
    }
  },
};

// export const actions: ActionTree<AnalyticsState, RootState> = {
// async setGroups(context: AnalyticsContext): Promise<void> {
//   context.commit("app/setLoading", true, { root: true });
//   try {
//     const groups = await GroupService.get();
//     context.commit("setGroups", groups);
//   } catch (e) {
//     console.log(e);
//   } finally {
//     context.commit("app/setLoading", false, { root: true });
//   }
// },
// };
