



















































































































































import Vue, { VueConstructor } from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import validationMixin, {
  ValidatableForm,
} from "@/components/mixins/validationMixin";
import dataTableMixin, {
  DataTableMixin,
} from "@/components/mixins/dataTableMixin";
import { Media, mediaConfig } from "@/models/media";
import cloneDeep from "lodash/cloneDeep";
import VaTextArea from "@/components/atoms/VaTextArea.vue";

export default (Vue as VueConstructor<DataTableMixin>).extend({
  name: "SignageMedia",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaTextArea,
    VaCard,
    VaTextField,
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaButton,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      update: false,
      storeName: "media",
      tableConfig: mediaConfig,
      newfile: undefined,
    };
  },
  methods: {
    onSave(): void {
      if ((this.$refs.editMediaForm as ValidatableForm).validate()) {
        if (this.update) {
          this.$store.dispatch("media/updateCurrentMedia");
        } else {
          this.$store.dispatch("media/createMedia", this.newfile);
        }
      }
    },
    onDelete(): void {
      this.$store.dispatch("media/deleteCurrentMedia");
    },
    onCreate(): void {
      this.update = false;
      this.newfile = undefined;
      this.$store.commit("media/resetCurrentMedia");
      this.dialog = true;
      this.$nextTick(() => {
        (
          this.$refs.editMediaForm as Vue & { resetValidation: () => void }
        ).resetValidation();
      });
    },
    onEdit(media: Media): void {
      this.$store.commit("media/setCurrentMedia", cloneDeep(media));
      this.$store.dispatch("media/getMedia", media.companyMediaId);
      this.update = true;
      this.dialog = true;
    },
  },
  computed: {
    currentMedia(): Media | undefined {
      return this.$store.getters["media/currenMedia"];
    },
    items(): Media[] {
      return this.$store.getters["media/media"];
    },
    dialog: {
      get(): boolean {
        return this.$store.getters["media/editing"];
      },
      set(dialog: boolean): void {
        this.$store.commit("media/setEditing", dialog);
      },
    },
  },
});
