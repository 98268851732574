

























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import { possibleGroups } from "@/models/policy";
import PoliciesList from "@/components/organisms/PoliciesList.vue";

export default Vue.extend({
  name: "Policies_Lists",
  data: () => ({
    // tabs: possibleGroups.filter(g => {
    //   return g == 'appearance' || this.$store.getters["session/isAdmin"]
    // }),
    window: 0,
  }),
  components: {
    MainColumns,
    Notifications,
    PoliciesList,
  },
  beforeMount() {
    if (!this.$store.getters["policy/currentGroup"]) {
      this.$store.commit("policy/setCurrentGroup", this.tabs[0]);
    } else {
      for (const [i, v] of this.tabs.entries()) {
        if (this.$store.getters["policy/currentGroup"] == v) {
          this.window = i;
        }
      }
    }
  },
  computed: {
    tabs(): string[] {
      return possibleGroups.filter((g) => {
        return g == "appearance" || this.$store.getters["session/isAdmin"];
      });
    },
  },
  methods: {
    onChange(e: number): void {
      this.$store.commit("policy/setCurrentGroup", this.tabs[e]);
      //clean query param
      const query = Object.assign({}, this.$route.query);
      delete query.preserveQuery;
      this.$router.replace({ query });

      this.$store.dispatch("policy/fetchList");
    },
  },
});
