import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthService from "@/services/authService";
import store from "@/store";
import { TokenData } from "@/models/token";
import { designSystemRoutes } from "@/router/designSystemRoutes";
import Dashboard from "@/components/pages/Dashboard.vue";
import Analytics from "@/components/pages/analytics/Analytics.vue";
import AnalyticsHealth from "@/components/pages/analytics/Health.vue";
import AnalyticsQuality from "@/components/pages/analytics/Quality.vue";
import AnalyticsUsage from "@/components/pages/analytics/Usage.vue";
import AnalyticsGreen from "@/components/pages/analytics/Green.vue";
import AnalyticsEfficency from "@/components/pages/analytics/Efficency.vue";
import Buildings from "@/components/pages/buildings/Buildings.vue";
import RoomsList from "@/components/pages/rooms/List.vue";
import RoomsDetail from "@/components/pages/rooms/Detail.vue";
import RoomsDetailInfo from "@/components/pages/rooms/DetailInfo.vue";
import RoomsDetailManagement from "@/components/pages/rooms/DetailManagement.vue";
import RoomsDetailEquipment from "@/components/pages/rooms/DetailEquipment.vue";
import RoomsDetailSensors from "@/components/pages/rooms/DetailSensors.vue";
import RoomsTags from "@/components/pages/rooms/Tags.vue";
import PodApplications from "@/components/pages/rooms/PodAppications.vue";
import RoomsGroups from "@/components/pages/rooms/Groups.vue";
import RoomsTasks from "@/components/pages/rooms/TaskScheduler.vue";
import Policies from "@/components/pages/rooms/Policies.vue";
import PoliciesDetail from "@/components/pages/rooms/PoliciesDetail.vue";
import Users from "@/components/pages/users/Users.vue";
import Licenses from "@/components/pages/Licenses.vue";
import LicenseDetail from "@/components/pages/LicenseDetail.vue";
import Account from "@/components/pages/account/Account.vue";
import Company from "@/components/pages/account/Company.vue";
import ExternalManagers from "@/components/pages/account/ExternalManagers.vue";
import ManagedAccount from "@/components/pages/account/ManagedAccount.vue";
import Logs from "@/components/pages/Logs.vue";
import Software from "@/components/pages/Software.vue";
import AccountUsers from "@/components/pages/account/Users.vue";
import Profile from "@/components/pages/Profile.vue";
import SignIn from "@/components/pages/auth/SignIn.vue";
import SignUp from "@/components/pages/auth/SignUp.vue";
import Confirm from "@/components/pages/auth/Confirm.vue";
import ResetPassword from "@/components/pages/auth/ResetPassword.vue";
import ForgotPassword from "@/components/pages/auth/ForgotPassword.vue";
import BuildingsDetail from "@/components/pages/buildings/BuildingsDetail.vue";
import BuildingsDetailEdit from "@/components/pages/buildings/BuildingsDetailEdit.vue";
import GroupsDetail from "@/components/pages/rooms/GroupsDetail.vue";
import UserDetail from "@/components/pages/users/UserDetail.vue";
import TaskSchedulerForm from "@/components/pages/rooms/TaskSchedulerForm.vue";
import AnalyticsOld from "@/components/dashboard/analytics/Analytics.vue";
import DashboardOld from "@/components/dashboard/Index.vue";
import tenantService from "@/services/tenantService";
import Integrations from "@/components/pages/Integrations.vue";
import Atmo from "@/components/pages/integrations/Atmo.vue";
import DashboardAtmo from "@/components/pages/public/DashboardAtmo.vue";
import DashboardAtmoAio from "@/components/pages/public/DashboardAtmoAio.vue";
import DashboardAtmoPoC from "@/components/pages/public/DashboardAtmoPoC.vue";
import DigitalSignage from "@/components/pages/digitalSignage/DigitalSignage.vue";
import SignageMedia from "@/components/pages/digitalSignage/Media.vue";
import SignagePlaylists from "@/components/pages/digitalSignage/Playlists.vue";
import PlaylistDetail from "@/components/pages/digitalSignage/PlaylistDetail.vue";
import { isAllowed, rolesDecoder } from "@/services/rolesService";

Vue.use(VueRouter);

// const redirectAuthorized = async (
//   to: Route,
//   from: Route,
//   next: NavigationGuardNext<Vue>
// ) => {
//   if (store.getters["session/user"]) {
//     next("/dashboard");
//   }
//   next();
// };

let routes: Array<RouteConfig> = [
  // ------------- free routes ------------- //
  {
    path: "/auth",
    redirect: "/auth/signin",
  },
  {
    path: "/auth/signin",
    name: "Signin",
    component: SignIn,
    // beforeEnter: redirectAuthorized,
  },
  {
    path: "/auth/signup",
    name: "SignUp",
    component: SignUp,
    // beforeEnter: redirectAuthorized,
  },
  {
    path: "/auth/confirm",
    name: "ConfirmSignUp",
    component: Confirm,
    // beforeEnter: redirectAuthorized,
  },
  {
    path: "/auth/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    // beforeEnter: redirectAuthorized,
  },
  {
    path: "/auth/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    // beforeEnter: redirectAuthorized,
  },
  {
    path: "/pub/d/atmo/:id",
    name: "Atmo",
    component: DashboardAtmo,
    props: (route) => ({
      publicDeviceId: route.params.id,
    }),
  },
  {
    path: "/pub/d/atmoaio/:id",
    name: "Atmo",
    component: DashboardAtmoAio,
    props: (route) => ({
      publicDeviceId: route.params.id,
    }),
  },
  {
    path: "/pub/d/atmopoc/:id",
    name: "Atmo",
    component: DashboardAtmoPoC,
    props: (route) => ({
      publicDeviceId: route.params.id,
    }),
  },
  // ------------- end free routes ------------- //
  // ------------- authorized routes ------------- //
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Home Dashboard",
    component: DashboardOld,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsList,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/tags",
    name: "Rooms tags",
    component: RoomsTags,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/applications",
    name: "Rooms applications",
    component: PodApplications,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/groups",
    name: "Rooms groups",
    component: RoomsGroups,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/rooms/groups/:id",
    name: "Rooms group detail",
    component: GroupsDetail,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/rooms/task-scheduler",
    name: "Rooms tasks",
    component: RoomsTasks,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/task-scheduler/new",
    name: "Rooms tasks create form",
    component: TaskSchedulerForm,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/task-scheduler/:id",
    name: "Rooms tasks detail",
    props: (route) => ({
      roomId: route.params.id,
    }),
    component: TaskSchedulerForm,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/rooms/policies/:id",
    name: "Policies Detail",
    component: PoliciesDetail,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/rooms/policies",
    name: "Rooms policies",
    component: Policies,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/rooms/:id",
    component: RoomsDetail,
    props: (route) => ({
      roomId: route.params.id,
    }),
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
    children: [
      {
        path: "",
        name: "Rooms detail",
        redirect: "info",
      },
      {
        path: "info",
        name: "Room info",
        component: RoomsDetailInfo,
        props: (route) => ({
          roomId: route.params.id,
        }),
      },
      {
        path: "management",
        name: "Room management",
        component: RoomsDetailManagement,
        props: (route) => ({
          roomId: route.params.id,
        }),
      },
      {
        path: "equipment",
        name: "Room equipment",
        component: RoomsDetailEquipment,
        props: (route) => ({
          roomId: route.params.id,
        }),
      },
      {
        path: "sensors",
        name: "Room sensors",
        component: RoomsDetailSensors,
        props: (route) => ({
          roomId: route.params.id,
        }),
      },
      {
        path: "analytics",
        name: "oldanalytics",
        component: AnalyticsOld,
      },
    ],
  },
  {
    path: "/users",
    component: Users,
    name: "Users list",
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/users/:id",
    name: "User detail",
    component: UserDetail,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/digital-signage",
    name: "DigitalSignage",
    component: DigitalSignage,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/digital-signage/media",
    name: "SignageMedia",
    component: SignageMedia,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/digital-signage/playlists",
    name: "SignagePlaylists",
    component: SignagePlaylists,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/digital-signage/playlists/:id",
    name: "SignagePlaylistDetail",
    component: PlaylistDetail,
    meta: {
      auth: true,
      requiredRoles: ["admin", "dsAdmin"],
    },
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: Integrations,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/integrations/atmo",
    name: "Integrations Atmo",
    component: Atmo,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: Licenses,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/licenses/:id",
    name: "Licenses Detail",
    component: LicenseDetail,
    props: (route) => ({
      licenseId: route.params.id,
    }),
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/account",
    component: Account,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
    children: [
      {
        path: "",
        name: "Company Account",
        component: Company,
      },
      {
        path: "external-managers",
        name: "ExternalManagers",
        component: ExternalManagers,
      },
      {
        path: "users",
        name: "users",
        component: AccountUsers,
      },
      {
        path: "managed-accounts",
        name: "ManagedAccount",
        component: ManagedAccount,
      },
    ],
  },
  {
    path: "/audit-logs",
    name: "Logs",
    component: Logs,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/download-software",
    name: "Software",
    component: Software,
    meta: {
      auth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/profile",
    name: "My Profile",
    component: Profile,
    meta: {
      auth: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

if (process.env.VUE_APP_DEBUG_MODE === "true") {
  //enable design system routes only for development
  const fakeDataRoutes: Array<RouteConfig> = [
    {
      path: "/dashboardnew",
      name: "Home Dashboard new",
      component: Dashboard,
      meta: {
        auth: true,
      },
    },
    {
      path: "/analytics",
      component: Analytics,
      meta: {
        auth: true,
      },
      children: [
        {
          path: "",
          name: "Analytics",
          redirect: "health",
        },
        {
          path: "health",
          name: "analytics health",
          component: AnalyticsHealth,
        },
        {
          path: "quality",
          name: "analytics quality",
          component: AnalyticsQuality,
        },
        {
          path: "usage",
          name: "analytics usage",
          component: AnalyticsUsage,
        },
        {
          path: "green",
          name: "analytics green",
          component: AnalyticsGreen,
        },
        {
          path: "efficency",
          name: "analytics efficency",
          component: AnalyticsEfficency,
        },
      ],
    },
    {
      path: "/buildings",
      name: "Buildings",
      component: Buildings,
    },
    {
      path: "/buildings/:id",
      component: BuildingsDetail,
      name: "Buildings detail",
      props: (route) => ({
        buildingId: route.params.id,
      }),
    },
    {
      path: "/buildings/:id/edit",
      component: BuildingsDetailEdit,
      name: "Buildings detail edit",
      props: (route) => ({
        buildingId: route.params.id,
      }),
    },
  ];
  routes = routes.concat(fakeDataRoutes);
  routes = routes.concat(designSystemRoutes);
}

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});

const recoverUserFromCookies = async (
  tokenData: TokenData
): Promise<boolean> => {
  try {
    const user = await AuthService.getUser();
    await store.dispatch("session/recoverLogin", {
      user: user,
      token: tokenData.token,
    });
    return true;
  } catch (err) {
    if (err?.response?.data?.errorCode === "U404") {
      AuthService.logout(true);
    }
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  document.title = `${tenantService.getTenantProductName()}`;
  const tokenData = AuthService.getTokenData() as TokenData;
  const shouldRecoverUser = tokenData.token && !store.getters["session/user"];
  if (to.matched.some((record) => record.meta.auth) || shouldRecoverUser) {
    await recoverUserFromCookies(tokenData);
  }
  const user = store.getters["session/user"];
  if (
    to.matched[0]?.meta.requiredRoles &&
    !isAllowed(user, to.matched[0]?.meta.requiredRoles)
  ) {
    if (user.roles.length > 0 && !user.roles.includes(rolesDecoder.admin)) {
      //TODO HACK!!! if user has roles and is not admin force redirect to groups page
      next("/rooms/groups");
    } else {
      next("/");
    }
  }
  next();
});

export default router;
