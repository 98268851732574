import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { Task, TaskDeserializer } from "@/models/scheduler";
import TaskService from "@/services/schedulerService";
import logger from "@/services/loggerService";
import * as listable from "./listableModule";
import { Command } from "@/models/command";
import i18n from "@/i18n";
import cloneDeep from "lodash/cloneDeep";
import SchedulerService from "@/services/schedulerService";
import router from "@/router";

export interface TaskState extends listable.ListableState {
  tasks: Task[];
  currentTask: Task;
  availableCommands: Command[];
}

type TaskContext = ActionContext<TaskState, RootState>;

export const namespaced = true;

const emptyTask = {
  name: "",
  commands: [],
  scheduleOptions: {
    type: "NEVER",
    startDate: undefined,
    endDate: undefined,
    recurrence: 1,
    weekDays: undefined,
    months: undefined,
    monthDays: undefined,
    randomDelay: 1,
  },
  targetType: "TAG",
  targets: [],
} as Task;

export const state = (): TaskState => ({
  ...listable.state,
  ...{
    tasks: [],
    currentTask: cloneDeep(emptyTask),
    availableCommands: [
      // {
      //   id: "1",
      //   code: "MACHINE_NAME",
      //   parameterType: "string",
      // },
      {
        id: "2",
        code: "VOLUME",
        parameterType: "boolean",
      },
      {
        id: "3",
        code: "VOLUME_LEVEL",
        parameterType: "number",
        parameter: 30,
      },
      {
        id: "4",
        code: "MICROPHONE",
        parameterType: "boolean",
      },
      {
        id: "5",
        code: "MICROPHONE_LEVEL",
        parameterType: "number",
        parameter: 30,
      },
      {
        id: "6",
        code: "LAUNCH",
        parameterType: "none",
      },
      {
        id: "7",
        code: "STOP",
        parameterType: "none",
      },
      {
        id: "9",
        code: "UPDATE",
        parameterType: "none",
      },
      {
        id: "16",
        code: "RESTART_MACHINE",
        parameterType: "none",
      },
      {
        id: "17",
        code: "RESTART_AIRSERVER",
        parameterType: "none",
      },
      {
        id: "20",
        code: "SCRIPT",
        parameterType: "file",
      },
    ],
  },
});

export const getters: GetterTree<TaskState, RootState> = {
  ...listable.getters,
  ...{
    tasks: (state): Task[] => {
      return state.tasks;
    },
    currentTask: (state): Task | undefined => {
      return state.currentTask;
    },
    availableCommands: (state): Command[] => {
      return state.availableCommands;
    },
  },
};

export const mutations: MutationTree<TaskState> = {
  ...listable.mutations,
  ...{
    setTasks(state: TaskState, tasks: Task[]) {
      state.tasks = tasks;
    },
    setCurrentTask(state: TaskState, task: Task) {
      state.currentTask = task;
    },
    resetCurrentTask(state: TaskState) {
      state.currentTask = cloneDeep(emptyTask);
    },
    addTask(state: TaskState, currentTask: Task) {
      state.tasks.push(currentTask);
    },
  },
};

export const actions: ActionTree<TaskState, RootState> = {
  ...listable.actions,
  ...{
    async fetchList(context: TaskContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await TaskService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setTasks", res.items.map(TaskDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async fetchDetail(context: TaskContext, id: string): Promise<void> {
      try {
        const res = await TaskService.get(id);
        context.commit("setCurrentTask", res);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async addTask(context: TaskContext, task: Task): Promise<void> {
      context.commit("setLoading", true);
      try {
        task.id = await TaskService.post(task);
        context.commit("addTask", task);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("schedulersLauncher.created"),
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: e,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    },

    async deleteCurrentTask(context: TaskContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("schedulersLauncher.deleteDialogTitle"),
          description: `${i18n.t("schedulersLauncher.deleteDescription")} "${
            context.state.currentTask?.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const task = context.state.currentTask;
            if (task?.id) {
              try {
                await SchedulerService.delete(task.id);
                router.push(`/rooms/task-scheduler`);
              } catch (e) {
                logger.error(e);
              } finally {
                context.commit("setLoading", false);
              }
            }
          },
        },
        { root: true }
      );
    },

    async updateTask(context: TaskContext, task: Task): Promise<void> {
      context.commit("setLoading", true);
      // const task = context.state.currentTask;
      if (task) {
        try {
          await TaskService.put(task);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("tasksList.updated"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
        }
      }
    },
  },
};
