import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import MediaService from "@/services/mediaService";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import * as listable from "./listableModule";
import cloneDeep from "lodash/clone";
import { Media, MediaDeserializer, emptyMedia } from "@/models/media";
import mediaService from "@/services/mediaService";

export interface MediaState extends listable.ListableState {
  currenMedia?: Media;
  media: Media[];
  editing: boolean;
  allMedia: Media[];
}

type MediaContext = ActionContext<MediaState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    media: [],
    currenMedia: cloneDeep(emptyMedia),
    editing: false,
    allMedia: [],
  },
};

export const state = (): MediaState => cloneDeep(initialState);

export const getters: GetterTree<MediaState, RootState> = {
  ...listable.getters,
  ...{
    currenMedia: (state): Media | undefined => {
      return state.currenMedia;
    },
    media: (state): Media[] => {
      return state.media;
    },
    allMedia: (state): Media[] => {
      return state.allMedia;
    },
    allImages: (state): Media[] => {
      return state.allMedia.filter((m) => {
        return m.fileType == "image";
      });
    },
    allVideo: (state): Media[] => {
      return state.allMedia.filter((m) => {
        return m.fileType == "video";
      });
    },
    editing: (state): boolean => {
      return state.editing;
    },
  },
};
export const mutations: MutationTree<MediaState> = {
  ...listable.mutations,
  ...{
    setCurrentMedia(state: MediaState, tag: Media) {
      state.currenMedia = tag;
    },
    resetCurrentMedia(state: MediaState) {
      state.currenMedia = cloneDeep(emptyMedia);
    },
    setMedia(state: MediaState, media: Media[]) {
      state.media = media;
    },
    updateMedia(state: MediaState, currenMedia: Media) {
      state.media = state.media.map((m) => {
        if (m.companyMediaId === currenMedia.companyMediaId) {
          return currenMedia;
        }
        return m;
      });
    },
    deleteMedia(state: MediaState, currenMedia: Media) {
      state.media = state.media.filter((m) => {
        return m.companyMediaId !== currenMedia.companyMediaId;
      });
    },
    addMedia(state: MediaState, currenMedia: Media) {
      state.media.push(currenMedia);
    },
    setEditing(state: MediaState, editing: boolean) {
      state.editing = editing;
    },
  },
};
export const actions: ActionTree<MediaState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: MediaContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.media = s.media;
      context.state.currenMedia = s.currenMedia;
      context.state.editing = s.editing;
      context.dispatch("resetListable");
    },
    async fetchList(context: MediaContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await MediaService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setMedia", res.items.map(MediaDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async deleteCurrentMedia(context: MediaContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("ds.deleteMedia"),
          description: `${i18n.t("ds.deleteMediaConfirm")} "${
            context.state.currenMedia?.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const media = context.state.currenMedia;
            if (media && media.companyMediaId) {
              try {
                await MediaService.delete(media.companyMediaId);
                context.commit("deleteMedia", media);
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: i18n.t("ds.deleteMediaSuccess"),
                    type: "success",
                  },
                  { root: true }
                );
              } catch (e) {
                if (e?.response?.data?.errorCode == "M404") {
                  context.commit(
                    "notifications/displayNotification",
                    {
                      message: e.response.data.message,
                      type: "warining",
                    },
                    { root: true }
                  );
                } else {
                  logger.error(e);
                  context.commit(
                    "notifications/displayNotification",
                    {
                      message: e,
                      type: "error",
                    },
                    { root: true }
                  );
                }
              } finally {
                context.commit("setLoading", false);
                context.commit("setEditing", false);
              }
            }
          },
        },
        { root: true }
      );
    },
    async createMedia(context: MediaContext, file: File): Promise<void> {
      context.commit("setLoading", true);
      const media = context.state.currenMedia;
      // async upload(file: File, notes = "") {
      //   this.isLoading = true;
      try {
        if (!file.type.includes("image") && !file.type.includes("video")) {
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("ds.errors.unsupportedFormat"),
              type: "error",
            },
            { root: true }
          );
          return;
        }
        if (file.size == 0) {
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("ds.errors.emptyFile"),
              type: "error",
            },
            { root: true }
          );
          return;
        }
        await mediaService.uploadFile(file, media?.note);
        context.dispatch("fetchList");
      } catch (err) {
        logger.error(err);
        context.commit(
          "notifications/displayNotification",
          {
            message: err,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
        context.commit("setEditing", false);
      }
    },
    async updateCurrentMedia(context: MediaContext): Promise<void> {
      context.commit("setLoading", true);
      const m = context.state.currenMedia;
      if (m) {
        try {
          await MediaService.put(m);
          context.commit("updateMedia", m);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("ds.mediaUpdated"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
          context.commit("setEditing", false);
        }
      }
    },
    async fetchAll(context: MediaContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        context.state.allMedia = await MediaService.fetch();
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async getMedia(context: MediaContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        context.state.currenMedia = await MediaService.get(id);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
  },
};
