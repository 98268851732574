var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "va-card",
        [
          _c("v-overlay", {
            attrs: {
              absolute: true,
              value: _vm.$store.getters["playlist/loading"] == true
            }
          }),
          _c(
            "div",
            { staticClass: "va-title d-flex align-self-center" },
            [
              _c("va-back", {
                attrs: {
                  target: "/digital-signage/playlists",
                  tooltip: _vm.$t("common.back")
                }
              }),
              _c("span", { staticClass: "ml-4" }, [
                _vm._v(" " + _vm._s(_vm.$t("ds.editPlaylist")))
              ])
            ],
            1
          ),
          _vm.playlist
            ? _c(
                "v-form",
                {
                  ref: "editPlaylistForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onSave },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.name")
                    },
                    model: {
                      value: _vm.playlist.name,
                      callback: function($$v) {
                        _vm.$set(_vm.playlist, "name", $$v)
                      },
                      expression: "playlist.name"
                    }
                  }),
                  _c("va-text-area", {
                    staticClass: "mb-4",
                    attrs: { label: _vm.$t("common.description") },
                    model: {
                      value: _vm.playlist.description,
                      callback: function($$v) {
                        _vm.$set(_vm.playlist, "description", $$v)
                      },
                      expression: "playlist.description"
                    }
                  }),
                  _c("va-switch", {
                    staticClass: "mb-8",
                    attrs: { text: _vm.$t("ds.youtubeOrMedia") },
                    model: {
                      value: _vm.playlist.config.youtubeTemplate,
                      callback: function($$v) {
                        _vm.$set(_vm.playlist.config, "youtubeTemplate", $$v)
                      },
                      expression: "playlist.config.youtubeTemplate"
                    }
                  }),
                  _vm.playlist.config.youtubeTemplate
                    ? _c("va-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("ds.youtubeUrl")
                        },
                        model: {
                          value: _vm.playlist.config.youtubeVideoId,
                          callback: function($$v) {
                            _vm.$set(_vm.playlist.config, "youtubeVideoId", $$v)
                          },
                          expression: "playlist.config.youtubeVideoId"
                        }
                      })
                    : _vm._e(),
                  !_vm.playlist.config.youtubeTemplate
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "va-subtitle" }, [
                            _vm._v(_vm._s(_vm.$t("ds.media")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("v-select", {
                                attrs: {
                                  "prepend-icon": "vi vi-folder-play",
                                  "append-icon": "vi vi-d-add",
                                  items: _vm.availableMedia,
                                  rules: [_vm.required],
                                  "item-text": "name",
                                  "item-value": "downloadUrl",
                                  outlined: "",
                                  dense: "",
                                  multiple: "",
                                  "return-object": "",
                                  label: _vm.$t("ds.choseMedia")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("div", [_vm._v(" ")]),
                                          index == 0
                                            ? _c("div", [
                                                _vm._v(_vm._s(item.name))
                                              ])
                                            : _vm._e(),
                                          index == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grey--text text-caption"
                                                },
                                                [
                                                  _vm._v(
                                                    " + " +
                                                      _vm._s(
                                                        _vm.playlist.config
                                                          .media.length - 1
                                                      ) +
                                                      " others "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex py-1" },
                                            [
                                              attrs["aria-selected"] == "true"
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-checkbox-marked "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    { staticClass: "mr-3" },
                                                    [
                                                      _vm._v(
                                                        " mdi-checkbox-blank-outline "
                                                      )
                                                    ]
                                                  ),
                                              _c("div", [
                                                _c(
                                                  "strong",
                                                  { staticClass: "d-block" },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-caption secondary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.fileType) +
                                                        " "
                                                    ),
                                                    item.note
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "- " +
                                                              _vm._s(item.note)
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1568574736
                                ),
                                model: {
                                  value: _vm.playlist.config.media,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playlist.config, "media", $$v)
                                  },
                                  expression: "playlist.config.media"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-simple-table", {
                            staticClass: "mb-6",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.name"))
                                            )
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ds.fileType"))
                                            )
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ds.extension"))
                                            )
                                          ]),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ds.duration"))
                                            )
                                          ]),
                                          _c("th", {
                                            staticClass: "text-right"
                                          })
                                        ])
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.playlist.config.media,
                                          function(media) {
                                            return _c(
                                              "tr",
                                              { key: media.companyMediaId },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(media.name))
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(media.fileType))
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(media.extension)
                                                  )
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    media.fileType == "image"
                                                      ? _c("va-text-field", {
                                                          attrs: {
                                                            rules: [
                                                              _vm.required,
                                                              _vm.maxNumber(
                                                                media.duration,
                                                                8400
                                                              ),
                                                              _vm.minNumber(
                                                                media.duration,
                                                                1
                                                              )
                                                            ],
                                                            number: "",
                                                            dense: ""
                                                          },
                                                          model: {
                                                            value:
                                                              media.duration,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                media,
                                                                "duration",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "media.duration"
                                                          }
                                                        })
                                                      : _c("span", [
                                                          _vm._v("auto")
                                                        ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c("va-button", {
                                                      attrs: {
                                                        icon: "vi vi-d-delete",
                                                        cta: _vm.$t(
                                                          "common.remove"
                                                        ),
                                                        color: "secondary",
                                                        type: "outline",
                                                        size: "m"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onRemoveMedia(
                                                            media
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              771208865
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("va-button", {
                        attrs: { cta: _vm.$t("common.delete"), color: "error" },
                        on: { click: _vm.onDelete }
                      }),
                      _c("va-button", {
                        staticClass: "ml-auto",
                        attrs: {
                          submit: "",
                          disabled: !_vm.valid,
                          loading: _vm.$store.getters["media/loading"],
                          cta: _vm.$t("common.save")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }