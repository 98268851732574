
















































































































import Vue, { VueConstructor } from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "UpdateConfiguration",
  components: { VaSwitch, VaTextField },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {
        AuthenticationOptions: { username: "", password: "" },
      } as unknown as { [key: string]: FieldValues },
      updateServerTypeOptions: [
        {
          value: 0,
          text: this.$t("updateConfiguration.VMCCloud"),
        },
        {
          value: 1,
          text: this.$t("updateConfiguration.custom"),
        },
        {
          value: 2,
          text: this.$t("updateConfiguration.VMCOnPremise"),
        },
      ],
      authenticationTypeOptions: [
        {
          value: 0,
          text: this.$t("updateConfiguration.none"),
        },
        {
          value: 1,
          text: this.$t("updateConfiguration.basic"),
        },
      ],
    };
  },
  methods: {
    extractVariables(): void {
      if (this.fields.UpdateServerType === 1) {
        const regex = /{.*?}/g;
        let variables: Array<string> =
          (this.fields.UpdateHostUrl as string).match(regex) || [];
        const urlVariables = {} as { [key: string]: string };
        variables.forEach((variable: string) => {
          if (variable === "{}") return;
          urlVariables[variable] =
            this.fields.UpdateHostOptions &&
            (this.fields.UpdateHostOptions as { [key: string]: string })[
              variable
            ]
              ? (this.fields.UpdateHostOptions as { [key: string]: string })[
                  variable
                ]
              : "";
        });
        this.fields.UpdateHostOptions = Object.assign(urlVariables);
      }
      return this.onChange();
    },
    handleAuthenticationOptions(): void {
      if (
        this.fields.AuthenticationType === 1 &&
        !this.fields.AuthenticationOptions
      ) {
        this.fields.AuthenticationOptions = { username: "", password: "" };
      }
      return this.onChange();
    },
  },
  mounted() {
    this.extractVariables();
  },
});
