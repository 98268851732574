import { saveAs } from "file-saver";
export default {
  download(file: File | Blob | string, filename = ""): void {
    saveAs(file, filename);
  },
  downloadByUrl(url: string, name = ""): void {
    const link = document.createElement("a");
    link.href = url;
    if (name.length > 0) {
      link.download = name;
    }
    link.click();
    URL.revokeObjectURL(link.href);
  },
  async downloadByResource(
    url: string,
    filename = "",
    extension: string
  ): Promise<void> {
    try {
      //const mimeType: string = MIME_TYPES[extension];
      const res = await fetch(url, {
        cache: "no-store",
        /*headers: {
          "Vary": "*",
          "Pragma": "no-cache",
          "Cache-Control": "no-store",
        },*/
      });
      const newBlob = await res.blob();
      //const blob = new Blob([file], { type: mimeType }); console.log("blob",blob);
      this.download(newBlob, filename + "." + extension);
    } catch (e) {
      console.log("Error on file download", e);
    }
  },
  //TODO: fix me
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  getFileName(res: any): string {
    if (!res.headers || !res.headers["content-disposition"]) return "";
    const contentDisposition = res.headers["content-disposition"];
    return contentDisposition
      .split(";")[1]
      .split("filename")[1]
      .split("=")[1]
      .replaceAll('"', "");
  },
};
