import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult, QueryTable } from "@/models/table";
import {
  Media,
  MediaDeserializer,
  MediaSearchSerializer,
} from "@/models/media";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = MediaSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.media.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async fetch(): Promise<Media[]> {
    //hack limit=0 on thise search means all items
    const data = {
      limit: 0,
      page: 1,
      sortBy: [],
      filterBy: null,
    } as QueryTable;
    const res: AxiosResponse = await axios.post(
      apiService.media.searchUrl(),
      data
    );
    return res.data.items.map(MediaDeserializer);
  },

  async put(media: Media): Promise<AxiosResponse> {
    return await axios.put(
      apiService.media.baseUrl() + "/" + media.companyMediaId,
      {
        name: media.name,
        note: media.note,
      }
    );
  },
  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.media.baseUrl() + "/" + id);
  },

  async get(id: string): Promise<Media> {
    const res = await axios.get(apiService.media.baseUrl() + "/" + id);
    return MediaDeserializer(res.data);
  },

  async uploadFile(file: File, notes = ""): Promise<void> {
    const res = await axios.post(apiService.media.uploadSession(), {
      name: file.name,
      sizeInBytes: file.size,
      note: notes,
      // fileHash: null,
    });
    if (res.data.uploadUrl) {
      // eslint-disable-next-line
      // @ts-ignore
      const fileData = Buffer.from(await file.arrayBuffer());
      await axios.put(res.data.uploadUrl, fileData, {
        headers: { ...res.data.headers, Authorization: null },
      });
      await axios.post(apiService.media.uploadSession() + "/commit", {
        companyMediaId: res.data.companyMediaId,
        uploadSessionId: res.data.uploadSessionId,
      });
    }
  },
};
