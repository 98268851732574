import { MutationTree, GetterTree, ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store";
import { UserList } from "@/models/user";
import LoggerService from "@/services/loggerService";
import UserService from "@/services/userService";
import { QueryTable } from "@/models/table";

export interface UsersState {
  currentUser: UserList | undefined;
  users: UserList[];
}

type UsersContext = ActionContext<UsersState, RootState>;

export const namespaced = true;

export const state = (): UsersState => ({
  currentUser: undefined,
  users: [] as UserList[],
});

export const getters: GetterTree<UsersState, RootState> = {
  currentUser: (state) => {
    return state.currentUser;
  },
  users: (state) => {
    return state.users;
  },
};

export const mutations: MutationTree<UsersState> = {
  setCurrentUser(state: UsersState, user: UserList | undefined) {
    state.currentUser = user;
  },
  setUsers(state: UsersState, users: UserList[]) {
    state.users = users;
  },
};

export const actions: ActionTree<UsersState, RootState> = {
  async getUsers(context: UsersContext) {
    try {
      //TODO MISSING API METHOD - temporary getting from list NEEDS MAJOR REFACTOR
      const data = {
        limit: 1000,
        page: 1,
        sortBy: [],
        filterBy: null,
      } as QueryTable;
      const res = await UserService.search(data);
      context.commit("setUsers", res.items);
    } catch (e) {
      LoggerService.error(e);
    }
  },
  // async setGroups(context: UsersContext): Promise<void> {
  //   context.commit("app/setLoading", true, { root: true });
  //   try {
  //     const groups = await GroupService.get();
  //     context.commit("setGroups", groups);
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     context.commit("app/setLoading", false, { root: true });
  //   }
  // },
};
