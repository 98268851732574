import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import LicenseService from "@/services/licenseService";
import {
  License,
  LicenseDeserializer,
  LicenseData,
  LicenseDetailRoomDeserializer,
  LicenseDetailUserDeserializer,
} from "@/models/license";
import * as listable from "./listableModule";
import logger from "@/services/loggerService";
import clone from "lodash/clone";
import i18n from "@/i18n";
import router from "@/router";
import cloneDeep from "lodash/cloneDeep";

export interface LicenseState extends listable.ListableState {
  currentLicense: License | undefined;
  licenses: License[];
}

type LicenseContext = ActionContext<LicenseState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    currentLicense: undefined,
    licenses: [],
  },
};

export const state = (): LicenseState => cloneDeep(initialState);

export const getters: GetterTree<LicenseState, RootState> = {
  ...listable.getters,
  ...{
    currentLicense: (state): License | undefined => {
      return state.currentLicense;
    },
    licenses: (state): License[] => {
      return state.licenses;
    },
  },
};

export const mutations: MutationTree<LicenseState> = {
  ...listable.mutations,
  ...{
    setCurrentLicense(state: LicenseState, license: License) {
      state.currentLicense = license;
    },
    resetCurrentLicense(state: LicenseState) {
      state.currentLicense = undefined;
    },
    setLicenses(state: LicenseState, licenses: License[]) {
      state.licenses = licenses;
    },
    addLicense(state: LicenseState, license: License) {
      state.licenses.push(license);
    },
  },
};

export const actions: ActionTree<LicenseState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: LicenseContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.currentLicense = s.currentLicense;
      context.state.licenses = s.licenses;
      context.dispatch("resetListable");
    },
    async fetchLicense(context: LicenseContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await LicenseService.getLicense(id);
        context.commit("setCurrentLicense", res);
      } catch (e) {
        console.log(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchCurrentLicenseDetails(context: LicenseContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const license = clone(context.state.currentLicense);
        if (license && license.id) {
          if (license.isPod) {
            const details = await LicenseService.detailRoomSearch(
              license.id,
              context.state.queryOptions,
              context.state.searchString
            );
            context.commit("setTotalItems", details.count);
            license.detailRooms = details.items.map(
              LicenseDetailRoomDeserializer
            );
          } else {
            const details = await LicenseService.detailUserSearch(
              license.id,
              context.state.queryOptions,
              context.state.searchString
            );
            context.commit("setTotalItems", details.count);
            license.detailUsers = details.items.map(
              LicenseDetailUserDeserializer
            );
          }
          context.commit("setCurrentLicense", license);
        }
      } catch (e) {
        console.log(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchList(context: LicenseContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await LicenseService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setLicenses", res.items.map(LicenseDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async addLicense(context: LicenseContext, data: LicenseData): Promise<any> {
      context.commit("setLoading", true);
      try {
        const res = await LicenseService.post(data);
        context.commit("addLicense", LicenseDeserializer(res));
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("licensesList.created"),
            type: "success",
          },
          { root: true }
        );
        context.dispatch("fetchList");
      } catch (e) {
        logger.error(e);
        let message = e.response.data.message ? e.response.data.message : e;
        if (e.response.data.errorCode == "L422") {
          message = i18n.t("licensesList.alreadyAssociated");
        }
        context.commit(
          "notifications/displayNotification",
          {
            message: message,
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
      }
    },
    async clearSeats(context: LicenseContext, id: string): Promise<void> {
      await LicenseService.clearSeats(id);
      return context.dispatch("fetchLicense", id);
    },
    async clearExtensions(context: LicenseContext, id: string): Promise<void> {
      await LicenseService.clearExtensions(id);
      return context.dispatch("fetchLicense", id);
    },
    async deleteLicense(context: LicenseContext, id: string): Promise<void> {
      await LicenseService.deleteLicense(id);
      router.push(`/licenses`);
    },
    // //OVERRIDE listable action
    // async updateSearchString(
    //   context: LicenseContext,
    //   search: string
    // ): Promise<void> {
    //   context.commit("setSearchString", search);
    //   if (context.state.currentLicense) {
    //     return context.dispatch("fetchCurrentLicenseDetails");
    //   }
    //   return context.dispatch("fetchList");
    // },
    // //OVERRIDE listable action
    // async updateQueryOptions(
    //   context: LicenseContext,
    //   options: OptionsTable
    // ): Promise<void> {
    //   context.commit("setQueryOptions", options);
    //   if (context.state.currentLicense) {
    //     return context.dispatch("fetchCurrentLicenseDetails");
    //   }
    //   return context.dispatch("fetchList");
    // },
  },
};
