
























































































































































































import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "AdvancedConfiguration",
  components: {
    VaTextField,
    VaSwitch,
  },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      CustomEmailServiceOptions: [
        {
          value: 0,
          text: "None",
        },
        {
          value: 1,
          text: "Custom SMTP",
        },
        // {
        //   value: 2,
        //   text: "Microsoft",
        // },
      ],
    };
  },
  methods: {
    onChangeCustomEmailService(val: number) {
      this.fields.UseSmtpToSendMail = val > 0;
      this.onChange();
    },
  },
});
