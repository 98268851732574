import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { RoomDeserializer, RoomDetail } from "@/models/room";
import RoomService from "@/services/roomService";
import { Tag } from "@/models/tag";
import LoggerService from "@/services/loggerService";
import * as listable from "./listableModule";
import logger from "@/services/loggerService";
import i18n from "@/i18n";

export interface RoomState extends listable.ListableState {
  room: RoomDetail | undefined;
  rooms: RoomDetail[];
  searchResults: RoomDetail[];
  isLoading: boolean;
}

type RoomContext = ActionContext<RoomState, RootState>;

export const namespaced = true;

export const state = (): RoomState => ({
  ...listable.state,
  ...{
    room: undefined,
    rooms: [],
    searchResults: [] as RoomDetail[],
    isLoading: false,
  },
});

export const getters: GetterTree<RoomState, RootState> = {
  ...listable.getters,
  ...{
    room: (state) => {
      return state.room;
    },
    rooms: (state) => {
      return state.rooms;
    },
    searchResults: (state): RoomDetail[] => {
      return state.searchResults;
    },
    isLoading: (state): boolean => {
      return state.isLoading;
    },
  },
};

export const mutations: MutationTree<RoomState> = {
  ...listable.mutations,
  ...{
    setLoading(state: RoomState, loading: boolean) {
      state.isLoading = loading;
    },
    setRoom(state: RoomState, room: RoomDetail | undefined) {
      state.room = room;
    },
    setRooms(state: RoomState, roomList: RoomDetail[]) {
      state.rooms = roomList;
    },
    setRoomTags(state: RoomState, tags: Array<Tag>) {
      if (state.room) {
        state.room.tags = tags;
      }
    },
    setSearchResults(state: RoomState, data: RoomDetail[]) {
      state.searchResults = data;
    },
    clearSearchResults(state: RoomState) {
      state.searchResults = [];
    },
  },
};

export const actions: ActionTree<RoomState, RootState> = {
  ...listable.actions,
  ...{
    async toggleMaintenance(
      context: RoomContext,
      data: {
        room: RoomDetail;
        note: string;
        maintenance: boolean;
      }
    ): Promise<void> {
      const maintenance = !data.room.isMaintenanceModeEnabled;
      if (data.room.isMaintenanceExpired) {
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("roomInformation.maintenanceExpired"),
            type: "error",
          },
          { root: true }
        );
      } else {
        try {
          if (!data.maintenance && data.room.maintenanceMode?.maintenanceId) {
            await RoomService.disableMaintenance(
              data.room.id,
              data.room.maintenanceMode.maintenanceId,
              data.note
            );
          } else {
            await RoomService.enableMaintenance(data.room.id, data.note);
          }
          context.commit(
            "notifications/displayNotification",
            {
              message: maintenance
                ? i18n.t("roomInformation.maintenanceModeON")
                : i18n.t("roomInformation.maintenanceModeOFF"),
              type: "success",
            },
            { root: true }
          );
        } catch (e) {
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.dispatch("fetchList");
          context.dispatch("fetchRoom", data.room.id);
          // this.updatingTags = false;
        }
      }
    },
    async fetchList(context: RoomContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await RoomService.search(
          context.state.queryOptions,
          context.state.searchString,
          context.state.additionalFilters
        );
        context.commit("setRooms", res.items.map(RoomDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async fetchRoom(context: RoomContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        const room = await RoomService.detail(id);
        context.commit("setRoom", room);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async setRoom(context: RoomContext, id: string): Promise<void> {
      //TODO candidate to removal
      try {
        const room = await RoomService.detail(id);
        context.commit("setRoom", room);
      } catch (e) {
        //this._vm.$notification.error("errore");
      }
    },
    async refreshRoom(context: RoomContext): Promise<void> {
      try {
        const roomId = this.state.room.room?.id;
        if (roomId === null) return;
        const room = await RoomService.detail(roomId as string);
        context.commit("setRoom", room);
      } catch (e) {
        logger.error(e);
      }
    },
    async getRooms(context: RoomContext) {
      //TODO: add filter by ids in the  API call
      try {
        const res = await RoomService.get();
        context.commit("setRooms", res);
      } catch (e) {
        LoggerService.error(e);
      }
    },
    async searchRooms(context: RoomContext, data: string) {
      context.commit("setLoading", true);
      //TODO: create search api
      try {
        const res = context.getters["rooms"].filter(
          (item: RoomDetail) => item.name.indexOf(data) > -1
        );
        context.commit("setSearchResults", res);
      } catch (err) {
        LoggerService.error(err);
      } finally {
        context.commit("setLoading", false);
      }
    },
  },
};
