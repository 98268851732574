




























































































































































































import Vue from "vue";
import VaAvatar from "@/components/atoms/VaAvatar.vue";
import VaIcon from "@/components/atoms/VaIcon.vue";
import store from "@/store";
import tenantService from "@/services/tenantService";
import { User } from "@/models/user";

export default Vue.extend({
  name: "Navigation",
  components: { VaAvatar, VaIcon },
  data() {
    return {
      drawer: null,
    };
  },
  mounted() {
    store.dispatch("onprem/getConfiguration");
  },
  methods: {},
  computed: {
    downloadDisabled() {
      return process.env.VUE_APP_DOWNLOAD_DISABLED === "true";
    },
    logo() {
      return tenantService.requireAsset("square.svg");
    },
    roomsExpanded: {
      get: function (): boolean {
        return this.$route.path.startsWith("/rooms");
      },
      set: function (): void {
        return;
      },
    },
    smallBreakpoint() {
      return (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      );
    },
    drawerOpen: {
      get: function (): boolean {
        return store.getters["app/displayMenu"] || !this.smallBreakpoint;
      },
      set: function (val: boolean): void {
        if (!val) {
          store.commit("app/setDisplayMenu", val);
        }
      },
    },
    user(): User {
      return this.$store.getters["session/user"];
    },
  },
});
