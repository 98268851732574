import Vue from "vue";
import Vuex from "vuex";
import * as session from "./sessionModule";
import * as app from "./appModule";
import * as policy from "./policyModule";
import * as room from "./roomModule";
import * as license from "./licenseModule";
import * as licenseDevices from "./licenseDevicesModule";
import * as group from "./groupModule";
import * as tag from "./tagModule";
import * as task from "./taskModule";
import * as analytics from "./analyticsModule";
import * as company from "./companyModule";
import * as users from "./usersModule";
import * as notifications from "./notificationsModule";
import * as mfa from "./mfaModule";
import * as building from "./buildingModule";
import * as cms from "./cmsModule";
import * as onprem from "./onPremModule";
import * as podApplication from "./podApplicationModule";
import * as media from "./mediaModule";
import * as playlist from "./playlistModule";

Vue.use(Vuex);

export interface RootState {
  session: session.SessionState;
  app: app.AppState;
  policy: policy.PolicyState;
  room: room.RoomState;
  license: license.LicenseState;
  licenseDevices: licenseDevices.LicenseDevicesState;
  group: group.GroupState;
  tag: tag.TagState;
  task: task.TaskState;
  analytics: analytics.AnalyticsState;
  users: users.UsersState;
  company: company.CompanyState;
  notifications: notifications.NotificationState;
  mfa: mfa.MFAState;
  building: building.BuildingState;
  cms: cms.CmsState;
  onprem: onprem.OnPremState;
  podApplication: podApplication.PodApplicationState;
  media: media.MediaState;
  playlist: playlist.PlaylistState;
}

export default new Vuex.Store({
  modules: {
    session,
    app,
    policy,
    room,
    license,
    licenseDevices,
    group,
    tag,
    task,
    analytics,
    users,
    company,
    notifications,
    mfa,
    building,
    cms,
    onprem,
    podApplication,
    media,
    playlist,
  },
});
