










import Vue from "vue";
import RoomPerformance from "@/components/dashboard/rooms/room/RoomPerformance.vue";
import RoomInformation from "@/components/organisms/rooms/RoomInformation.vue";
import { RoomDetail } from "@/models/room";
import PerformanceLive from "@/components/dashboard/performance/Performance.vue";

export default Vue.extend({
  name: "RoomDetailInfo",
  components: {
    PerformanceLive,
    RoomPerformance,
    RoomInformation,
  },
  data() {
    return {
      // error: "",
      // updateRequiredDialog: false
    };
  },
  // async beforeMount() {
  //   this.polling = setInterval(() => { //TODO MOVE TO PARENT COMPONENT?
  //     this.$store.dispatch("room/refreshRoom");
  //   }, 5000);
  // },
  // beforeDestroy() {
  //   clearInterval(this.polling);
  // },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
