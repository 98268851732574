var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c("room-information", { staticClass: "va-card mb-6" }),
        _c("room-performance", { staticClass: "va-card mb-6" }),
        _c("performance-live", { staticClass: "va-card" })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }