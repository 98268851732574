var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("rooms-map", { attrs: { markers: _vm.marker } })]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "va-title d-flex align-self-center" },
        [
          _c("va-back", {
            attrs: {
              target: "/rooms/groups",
              tooltip: _vm.$t("groupsList.back")
            }
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v(_vm._s(_vm.$t("groupsList.editTitle")))
          ])
        ],
        1
      ),
      _vm.group
        ? _c(
            "va-card",
            [
              _c(
                "v-form",
                {
                  ref: "editGroupForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: {
                    submit: function($event) {
                      return _vm.$store.dispatch("group/updateCurrentGroup")
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.name")
                    },
                    model: {
                      value: _vm.group.name,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "name", $$v)
                      },
                      expression: "group.name"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.description")
                    },
                    model: {
                      value: _vm.group.description,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "description", $$v)
                      },
                      expression: "group.description"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("common.address"),
                      name: "groupAddress"
                    },
                    on: { change: _vm.onChange, input: _vm.onAddressChanged },
                    model: {
                      value: _vm.group.address.formattedAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.group.address, "formattedAddress", $$v)
                      },
                      expression: "group.address.formattedAddress"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right mt-6" },
                    [
                      _vm.$store.getters["session/isAdmin"]
                        ? _c("va-button", {
                            staticClass: "mr-4",
                            attrs: {
                              cta: _vm.$t("common.delete"),
                              color: "error"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$store.dispatch(
                                  "group/deleteCurrentGroup"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _c("va-button", {
                        attrs: {
                          disabled: !_vm.valid,
                          submit: "",
                          loading: _vm.$store.getters["group/isLoading"],
                          cta: _vm.$t("common.save")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tabs",
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("common.policies")))]),
                  _c(
                    "v-tab",
                    {
                      attrs: {
                        disabled: !_vm.$store.getters["session/isAdmin"]
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.meetingRooms")) + " ")]
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pt-6" },
                    [
                      _vm.group.policies
                        ? _c("policies-chooser", {
                            attrs: {
                              policies: _vm.group.policies,
                              "group-id": _vm.group.id,
                              "hide-icons": true
                            }
                          })
                        : _vm._e(),
                      _vm.$store.getters["session/isPlaylistEditor"]
                        ? _c("va-button", {
                            staticClass: "mb-4 float-right",
                            attrs: {
                              loading: _vm.$store.getters["policy/isLoading"],
                              cta: _vm.$t("policiesGroups.applyAll")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$store.dispatch(
                                  "policy/applyToGroup",
                                  _vm.group.id
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "pt-6" },
                    [_c("rooms-list-table", { attrs: { group: _vm.group } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }