












































































































































































import Vue, { VueConstructor } from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import validationMixin, {
  ValidatableForm,
} from "@/components/mixins/validationMixin";
import { Playlist } from "@/models/playlist";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextArea from "@/components/atoms/VaTextArea.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import { Media } from "@/models/media";
import VaBack from "@/components/molecules/VaBack.vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import PlaylistService from "@/services/playlistService";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof validationMixin>>
).extend({
  name: "SignagePlaylistDetail",
  mixins: [validationMixin],
  components: {
    VaSwitch,
    VaBack,
    VaButton,
    VaTextArea,
    VaTextField,
    VaCard,
    MainColumns,
    Notifications,
  },
  data() {
    return {};
  },
  async beforeMount() {
    if (this.$route.params.id) {
      this.$store.dispatch("playlist/getPlaylist", this.$route.params.id);
      this.$store.dispatch("media/fetchAll");
    }
  },
  methods: {
    onRemoveMedia(item: Media): void {
      if (this.playlist) {
        const list = this.playlist.config.media.filter(
          (m) => m.companyMediaId != item.companyMediaId
        );
        this.playlist.config.media = list;
        this.playlist.media = list;
      }
    },
    onDelete(): void {
      this.$store.dispatch("playlist/deletePlaylist");
    },
    onSave(): void {
      if (
        (this.$refs.editPlaylistForm as ValidatableForm).validate() &&
        this.playlist
      ) {
        if (this.playlist.config.youtubeTemplate) {
          this.playlist.config.youtubeVideoId = PlaylistService.youtubeParser(
            this.playlist.config.youtubeVideoId
          ) as string;
          this.playlist.media = [];
          this.playlist.config.media = [];
        } else {
          this.playlist.config.youtubeVideoId = "";
          this.playlist.media = this.playlist.config.media;
        }
        this.$store.dispatch("playlist/updatePlaylist", this.playlist);
      }
    },
  },
  computed: {
    playlist(): Playlist | undefined {
      return this.$store.getters["playlist/currenPlaylist"];
    },
    availableMedia(): Media[] | undefined {
      return this.$store.getters["media/allMedia"].filter((m: Media) => {
        return m.fileType == "video" || m.fileType == "image";
      });
    },
  },
});
