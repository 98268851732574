import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import LoggerService from "@/services/loggerService";
import { ReleaseNotes } from "@/models/releaseNotes";
import cmsService from "@/services/cmsService";
import FileService from "@/services/fileService";

export interface CmsState {
  releaseNotes: ReleaseNotes[];
  loading: boolean;
}

type AppContext = ActionContext<CmsState, RootState>;

export const namespaced = true;

export const state = (): CmsState => ({
  releaseNotes: [],
  loading: false,
});

export const getters: GetterTree<CmsState, RootState> = {
  releaseNotes: (state) => {
    return state.releaseNotes;
  },
  loading: (state): boolean => {
    return state.loading;
  },
};

export const mutations: MutationTree<CmsState> = {
  setReleaseNotes(state: CmsState, notes: ReleaseNotes[]) {
    state.releaseNotes = notes;
  },
  setLoading(state: CmsState, loading: boolean) {
    state.loading = loading;
  },
};

export const actions: ActionTree<CmsState, RootState> = {
  async fetchReleaseNotes(context: AppContext): Promise<void> {
    if (context.state.releaseNotes.length == 0) {
      try {
        context.commit("setLoading", true);
        const res = await cmsService.getReleaseNotes();
        res.map(async (r) => {
          if (!r.url && r.product == "room") {
            r.url = await FileService.latestVersion(r.fastChannel);
          }
          return r;
        });

        context.commit("setReleaseNotes", res);
      } catch (e) {
        LoggerService.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    }
  },
};
