




















































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Tag, tagConfig } from "@/models/tag";
import VaButton from "@/components/atoms/VaButton.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import clone from "lodash/clone";
import validationMixin, {
  ValidatableForm,
} from "@/components/mixins/validationMixin";
import dataTableMixin from "@/components/mixins/dataTableMixin";

export default Vue.extend({
  name: "DigitalSignage",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaCard,
    VaTextField,
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaButton,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      update: false,
      storeName: "tag",
      tableConfig: tagConfig,
    };
  },
  methods: {
    onSave(): void {
      if ((this.$refs.editTagForm as ValidatableForm).validate()) {
        if (this.update) {
          this.$store.dispatch("tag/updateCurrentTag");
        } else {
          this.$store.dispatch("tag/createTag");
        }
      }
    },
    onDelete(): void {
      this.$store.dispatch("tag/deleteCurrentTag");
    },
    onCreate(): void {
      this.update = false;
      this.$store.commit("tag/resetCurrentTag");
      this.dialog = true;
      this.$nextTick(() => {
        (
          this.$refs.editTagForm as Vue & { resetValidation: () => void }
        ).resetValidation();
      });
    },
    onEdit(tag: Tag): void {
      this.$store.commit("tag/setCurrentTag", clone(tag));
      this.update = true;
      this.dialog = true;
    },
  },
  computed: {
    tag(): Tag | undefined {
      return this.$store.getters["tag/currentTag"];
    },
    tags(): Tag[] {
      return this.$store.getters["tag/tags"];
    },
    dialog: {
      get(): boolean {
        return this.$store.getters["tag/editing"];
      },
      set(dialog: boolean): void {
        this.$store.commit("tag/setEditing", dialog);
      },
    },
  },
});
