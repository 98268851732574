let API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
let API_BASE_URL_V2 = process.env.VUE_APP_API_BASE_URL_V2;
const API_VERSION = process.env.VUE_APP_API_VERSION;
const MAPBOX_TOKEN = process.env.VUE_APP_MAPBOX_TOKEN;
const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY;

//in a on premise environment check the custom onprem config
try {
  if (window.ONPREM_API_BASE_URL) API_BASE_URL = window.ONPREM_API_BASE_URL;
  if (window.ONPREM_API_BASE_URLAPI_BASE_URL_V2)
    API_BASE_URL_V2 = window.ONPREM_API_BASE_URLAPI_BASE_URL_V2;
} catch (e) {
  console.log("no On Premise Configuration, skipping");
}

export default {
  auth: {
    checkUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/auth/email/check`;
    },
    signinUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/token`;
    },
    signupUrl(): string {
      //   return `${API_BASE_URL_V2}/${API_VERSION}/auth/signup`;
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/fast-signup`;
    },
    confirmMailUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/email/confirmation`;
    },
    forgotPasswordUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/password/reset/request`;
    },
    resetPasswordUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/password/reset`;
    },
    refreshTokenUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/token-refresh`;
    },
    mfaCheckCodeUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/token/mfa/send-code`;
    },
  },
  admin: {
    baseUrl(): string {
      // https://vmc-api.valarea.com/api/v1/users/me
      return `${API_BASE_URL}/api/${API_VERSION}/users/me`;
    },
    companyUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/companies`;
    },
    myMfaUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/auth/me/mfa/providers`;
    },
    mfaProvidersUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa/providers`;
    },
    mfaEnabledProvidersUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/me/mfa/providers`;
    },
    mfaEnableCodeUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa/send-code`;
    },
    mfaVerificationCodeUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa/verify-code`;
    },
    confirmMFAActivationUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa`;
    },
    backupCodesUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa/backup-codes`;
    },
    resetBackupCodesUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa/refresh-backup-codes`;
    },
    providersChallengeUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/token/mfa/challenge`;
    },
    mfaDisableProviderUrl(): string {
      return `${API_BASE_URL_V2}/${API_VERSION}/auth/mfa`;
    },
  },
  file: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/files`;
    },
    latestVersionUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/files/versions`;
    },
  },
  room: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/search`;
    },
    detailUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id`;
    },
    getCommandUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id`;
    },
    sendCommandUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/start-command`;
    },
    stopCommandUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/stop-command`;
    },
    enableMaintenanceUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/maintenance`;
    },
    disableMaintenanceUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/maintenance/:maintenanceId`;
    },
    overallState(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/status-chart`;
    },
    policiesUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/policies`;
    },
    applyPolicyUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:pod-id/policies/:policy-id`;
    },
    removePolicyUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:pod-id/policies/:policy-id`;
    },
    applyAllPolicies(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/apply-policies`;
    },
    lastCommandsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/pods/:id/commands`;
    },
    searchBuildsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/builds/search`;
    },
    installBuildsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/builds/install`;
    },
  },
  countriesUrl(): string {
    return `https://restcountries.eu/rest/v2/all`;
  },
  dashboard: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/dashboard`;
    },
    topRoomsChart(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/dashboard/top-chart`;
    },
    meetingCountChart(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/dashboard/meetings-count-chart`;
    },
  },
  license: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/licenses`;
    },
    devBaseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/dev/licenses`;
    },
    baseDEVUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/dev/licenses`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/licenses/search`;
    },
    detailUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/licenses/:id`;
    },
    detailSearchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/licenses/:id/search`;
    },
  },
  usersSearchUrl(): string {
    return `${API_BASE_URL}/api/${API_VERSION}/users/search`;
  },
  usersUrl(): string {
    return `${API_BASE_URL}/api/${API_VERSION}/users`;
  },
  tag: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/tags`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/tags/search`;
    },
  },
  podApplication: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/podapplications`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/podapplications/search`;
    },
  },
  policy: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/policies`;
    },
    groupsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/policies/groups`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/policies/search`;
    },
    fieldsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/policies/:id/fields`;
    },
    defaultsUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/policies/policyGroup/:id/defaults`;
    },
  },
  group: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/groups`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/groups/search`;
    },
  },
  analytic: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/analytics`;
    },
  },
  scheduler: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/schedulers`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/schedulers/search`;
    },
  },
  log: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/logs`;
    },
  },
  performance: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/performance`;
    },
  },
  map: {
    darkThemeUrl(): string {
      // return `https://api.mapbox.com/styles/v1/remago/ckx8qcpkga8cs14pab8r583jc/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}&zoomwheel=true&fresh=true#1.87/-12.6/-16.88`;
      return `https://api.mapbox.com/styles/v1/remago/ckxio7ilb7eh514pbg8zb3f4x/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}&zoomwheel=true&fresh=true#1.87/-12.6/-16.88`;
    },
    lightThemeUrl(): string {
      // return `https://api.mapbox.com/styles/v1/remago/ckx92mg6d11wv14obht927qqq/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}&zoomwheel=true&fresh=true#1.87/-12.6/-16.88`;
      return `https://api.mapbox.com/styles/v1/remago/cm2lqoxvu008y01r6dqpv5t33/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_TOKEN}&zoomwheel=true&fresh=true#1.87/-12.6/-16.88`;
    },
    coordinates(address: string): string {
      return `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`;
    },
  },
  integrations: {
    atmo: {
      integrationParameters(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/parameters`;
      },
      devices(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/devices`;
      },
      deviceRelations(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/devices/:id/pod`;
      },
      deviceLatestMeasurements(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/devices/:id/latest_measurements`;
      },
      deviceMeasurements(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/devices/:id/measurements`;
      },
      podAtmoDashboard(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/dashboard/:id`;
      },
      podAtmoDashboardPublic(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/public/devices/:id/dashboard`;
      },
      deviceLatestMeasurementsPublic(): string {
        return `${API_BASE_URL}/api/${API_VERSION}/integrations/atmo/public/devices/:id/latest_measurements`;
      },
    },
  },
  onPrem: {
    configuration(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/onPremLicenses/environmentConfiguration`;
    },
    import(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/onPremLicenses/import`;
    },
  },
  media: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/media`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/media/search`;
    },
    uploadSession(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/media/upload-session`;
    },
  },
  playlist: {
    baseUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/playlist`;
    },
    searchUrl(): string {
      return `${API_BASE_URL}/api/${API_VERSION}/playlist/search`;
    },
  },
};
