
































































































































import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";
import VaTextField from "@/components/atoms/VaTextField.vue";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "SecurityConfiguration",
  components: { VaTextField, VaSwitch },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      bluetoothProximityLevelsOptions: [
        {
          value: "Low",
          text: this.$t("common.low"),
        },
        {
          value: "Medium",
          text: this.$t("common.medium"),
        },
        {
          value: "High",
          text: this.$t("common.high"),
        },
      ],
      minutesBeforeOptions: [
        {
          value: 10,
          text: "10 " + this.$t("common.minutesBefore"),
        },
        {
          value: 15,
          text: "15 " + this.$t("common.minutesBefore"),
        },
        {
          value: 30,
          text: "30 " + this.$t("common.minutesBefore"),
        },
      ],
    };
  },
  watch: {
    // "fields.UsePodExternalServer": function (newVal) {
    //   if (newVal === "Via Internet") {
    //     this.fields.BleProximityEnabled = true;
    //   }
    // },
    "fields.BleProximityEnabled": function (newVal) {
      if (newVal && !this.fields.bleProximityRange) {
        this.fields.bleProximityRange = "medium";
      }
    },
  },
});
