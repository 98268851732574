import i18n from "@/i18n";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";
import { Media, MediaDeserializer, MediaSerializer } from "@/models/media";
import cloneDeep from "lodash/cloneDeep";

const currentPlaylistVersion = 1.1;

interface PlaylistConfiguration {
  youtubeTemplate: boolean;
  youtubeVideoId: string;
  // videoUrl: string;
  // videoType: string;
  // imageUrl: string;
  version: number;
  slideDuration: number;
  media: Media[];
}

export interface Playlist {
  playlistId: string;
  publicPlaylistId: string;
  name: string;
  description: string;
  creationDate: Date;
  updateDate?: Date;
  media: Media[];
  config: PlaylistConfiguration;
}

export interface ShortPlaylist {
  playlistId: string;
  name: string;
  description: string;
}

export const emptyPlaylist: Playlist = {
  playlistId: "",
  publicPlaylistId: "",
  name: "",
  description: "",
  creationDate: new Date(),
  updateDate: undefined,
  media: [],
  config: {
    version: currentPlaylistVersion,
    slideDuration: 0,
    media: [],
    youtubeTemplate: false,
    youtubeVideoId: "",
  },
};

export const playlistConfig = {
  playlistId: {
    dtoName: "playlistId",
    sortable: false,
    filterable: false,
    label: i18n.t("common.id"),
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  // publicPlaylistId: {
  //   dtoName: "publicPlaylistId",
  //   sortable: false,
  //   filterable: true,
  //   label: i18n.t("ds.publicId"),
  // },
  description: {
    dtoName: "description",
    sortable: false,
    filterable: true,
    label: i18n.t("common.description"),
  },
  creationDate: {
    dtoName: "creationDate",
    sortable: true,
    filterable: false,
    label: i18n.t("ds.creationDate"),
  },
  // updateDate: {
  //   dtoName: "updateDate",
  //   sortable: true,
  //   filterable: false,
  //   label: i18n.t("ds.updateDate"),
  // },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function PlaylistDeserializer(input: any): Playlist {
  return {
    playlistId: input.playlistId || "",
    publicPlaylistId: input.publicPlaylistId || "",
    name: input.name || "",
    description: input.description || "",
    creationDate: new Date(input.creationDate),
    updateDate: input.updateDate ? new Date(input.updateDate) : undefined,
    media: input.media ? input.media.map(MediaDeserializer) : [],
    config: input.config
      ? PlaylistConfigurationDeserializer(input.config)
      : emptyPlaylist.config,
  };
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function ShortPlaylistDeserializer(input: any): ShortPlaylist {
  return {
    playlistId: input.playlistId || "",
    name: input.name || "",
    description: input.description || "",
  };
}

export function PlaylistConfigurationDeserializer(
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  input: any
): PlaylistConfiguration {
  return {
    version: input.version || 0,
    slideDuration: input.slideDuration || 0,
    media: input.media ? input.media.map(MediaDeserializer) : [],
    youtubeTemplate: input.youtubeTemplate || false,
    youtubeVideoId: input.youtubeVideoId || "",
    // videoUrl: input.videoUrl || "",
    // videoType: input.videoType || "",
    // imageUrl: input.imageUrl || "",
  };
}

export function PlaylistSerializer(playlist: Playlist): unknown {
  const res = cloneDeep(playlist) as any;
  res.media = [
    ...new Map(
      res.media.map((item: Media) => [
        item.companyMediaId,
        MediaSerializer(item),
      ])
    ).values(),
  ]; //unique
  res.config = PlaylistConfigurationSerializer(playlist.config);
  return res;
}

export function PlaylistConfigurationSerializer(
  config: PlaylistConfiguration
): unknown {
  return {
    version: currentPlaylistVersion,
    slideDuration: config.slideDuration,
    media: config.media.map(MediaSerializer),
    youtubeTemplate: config.youtubeTemplate,
    youtubeVideoId: config.youtubeVideoId,
  };
  // if (config.template === "youtube") {
  //   const id = playlistService.youtubeParser(config.youtubeVideoId);
  //   debugger;
  //   return {
  //     template: config.template,
  //     youtubeVideoId: id,
  //   };
  // } else if (config.template == "video") {
  //   return {
  //     template: config.template,
  //     videoUrl: config.videoUrl,
  //     videoType: config.videoType,
  //   };
  // } else if (config.template == "image") {
  //   return {
  //     template: config.template,
  //     imageUrl: config.imageUrl,
  //   };
  // }
}

export function PlaylistSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, playlistConfig),
    filterBy: { values: FilterBySerializer(playlistConfig, searchString) },
  };
}
