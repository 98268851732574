













































































































































import Vue from "vue";
import { DashboardData } from "@/models/dashboard";
import VaNotification from "@/components/molecules/VaNotification.vue";

export default Vue.extend({
  components: { VaNotification },
  data() {
    return {
      downloading: false,
      polling: 0,
    };
  },
  async beforeMount(): Promise<void> {
    if (this.dashboard == undefined) {
      this.$store.dispatch("app/setDashboard", false);
    }
    this.polling = setInterval(async () => {
      this.$store.dispatch("app/setDashboard", true);
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {},
  computed: {
    dashboard(): DashboardData {
      return this.$store.getters["app/dashboard"];
    },
  },
});
