












































































































import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";
import VaTextField from "@/components/atoms/VaTextField.vue";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "HomeLauncherConfiguration",
  components: { VaTextField, VaSwitch },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      wiredHdmiOptions: [
        {
          value: "0",
          text: this.$t("policiesFields.BYOD") as string,
        },
        {
          value: "1",
          text: this.$t("policiesFields.BYOM") as string,
        },
      ],
    };
  },
  methods: {
    updateHdmiBarco(val: boolean) {
      this.fields.WiredHdmiWithBarco = val;
      if (val) {
        this.fields.WiredHdmiMonitorEnabled = false;
        if (this.fields.WiredHdmiWithThirdPartyMode == undefined) {
          this.fields.WiredHdmiWithThirdPartyMode = "0";
        }
      }
      this.onChange();
    },
    updatePresentationUrl(val?: string) {
      if (val) {
        this.fields.PresentationUrl = val;
        this.fields.WiredHdmiShowInfo = false;
        this.fields.WirelessShowInfo = false;
      } else {
        this.fields.PresentationUrl = "";
      }
      this.onChange();
    },
  },
});
