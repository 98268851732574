










































import Vue, { VueConstructor } from "vue";
import { Policy, policyConfig } from "@/models/policy";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import dataTableMixin, {
  DataTableMixin,
} from "@/components/mixins/dataTableMixin";

export default (Vue as VueConstructor<DataTableMixin>).extend({
  name: "PoliciesList",
  mixins: [dataTableMixin],
  components: {
    VaDetailsButton,
    VaCard,
    VaTableTop,
    VaNoDataTag,
    VaChip,
  },
  props: {},
  data() {
    return {
      storeName: "policy",
      tableConfig: policyConfig,
    };
  },
  methods: {},
  computed: {
    title(): string {
      return (
        this.$t(
          `policiesGroups.${this.$store.getters["policy/currentGroup"]}`
        ) +
        " " +
        this.$t("common.policies")
      );
    },
    policies(): Policy[] {
      return this.$store.getters["policy/policies"];
    },
    group(): Policy[] {
      return this.$store.getters["policy/currentGroup"];
    },
  },
  watch: {
    group() {
      this.resetQueryOptions();
    },
  },
});
