import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import { DashboardData } from "@/models/dashboard";
import DashboardService from "@/services/dashboardService";
import { LightBox } from "@/models/lightBox";
import RoomService from "@/services/roomService";
import { DateTime } from "luxon";
import vuetify from "@/plugins/vuetify";
import LoggerService from "@/services/loggerService";

export interface AppState {
  showNavigation: boolean;
  showNotifications: boolean;
  loading: boolean;
  dashboard: DashboardData | null;
  lightBox: LightBox;
  displayMenu: boolean;
  overallStatus: any; //TODO TEMPORARY
}

type AppContext = ActionContext<AppState, RootState>;

export const namespaced = true;

export const state = (): AppState => ({
  showNavigation: false,
  showNotifications: false,
  loading: false,
  dashboard: null,
  lightBox: {
    index: null,
    items: [],
  },
  displayMenu: false,
  overallStatus: undefined,
});

export const getters: GetterTree<AppState, RootState> = {
  showNavigation: (state) => {
    return state.showNavigation;
  },
  showNotifications: (state) => {
    return state.showNotifications;
  },
  loading: (state) => {
    return state.loading;
  },
  dashboard: (state) => {
    return state.dashboard;
  },
  overallStatus: (state) => {
    return state.overallStatus;
  },
  lightBox: (state) => {
    return state.lightBox;
  },
  displayMenu: (state) => {
    return state.displayMenu;
  },
};

export const mutations: MutationTree<AppState> = {
  setShowNavigation(state: AppState, showNavigation: boolean) {
    state.showNavigation = showNavigation;
  },
  setShowNotifications(state: AppState, showNotifications: boolean) {
    state.showNotifications = showNotifications;
  },
  toggleNavigation(state: AppState) {
    state.showNavigation = !state.showNavigation;
  },
  toggleNotifications(state: AppState) {
    state.showNotifications = !state.showNotifications;
  },
  setLoading(state: AppState, loading) {
    state.loading = loading;
  },
  setDashboard(state: AppState, dashboard) {
    state.dashboard = dashboard;
  },
  setOverallStatus(state: AppState, overallStatus) {
    state.overallStatus = overallStatus;
  },
  closeLightBox(state: AppState) {
    state.lightBox.items = [];
    state.lightBox.index = null;
  },
  openLightBox(state: AppState, data: LightBox) {
    state.lightBox = data;
  },
  toggleMenu(state: AppState) {
    state.displayMenu = !state.displayMenu;
  },
  setDisplayMenu(state: AppState, display: boolean) {
    state.displayMenu = display;
  },
};

export const actions: ActionTree<AppState, RootState> = {
  async clearDashboard(context: AppContext): Promise<void> {
    context.commit("setDashboard", null);
    context.commit("setOverallStatus", undefined);
  },
  async setDashboard(context: AppContext, silent = false): Promise<void> {
    try {
      if (!silent) {
        context.commit("setLoading", true);
      }
      const dashboard = await DashboardService.get();
      context.commit("setDashboard", dashboard);
      if (!silent) {
        context.commit("setLoading", false);
      }
    } catch (e) {
      LoggerService.error(e);
    }
  },
  async fetchRoomsOverallState(context: AppContext): Promise<void> {
    try {
      context.commit("setLoading", true);
      const res = await RoomService.overallState("weekly");
      const statusChart = {
        labels: res.map((item) => {
          return DateTime.fromMillis(item.date).toFormat("dd/LL");
        }),
        datasets: [
          {
            label: "Ok",
            backgroundColor: res.map(() => {
              return vuetify.framework.theme.currentTheme.success;
            }),
            data: res.map((item) => {
              return item.values[0];
            }),
          },
          {
            label: "Warning",
            backgroundColor: res.map(() => {
              return vuetify.framework.theme.currentTheme.warning;
            }),
            data: res.map((item) => {
              return item.values[1];
            }),
          },
          {
            label: "Critical",
            backgroundColor: res.map(() => {
              return vuetify.framework.theme.currentTheme.error;
            }),
            data: res.map((item) => {
              return item.values[2];
            }),
          },
          {
            label: "Offline",
            backgroundColor: res.map(() => {
              return vuetify.framework.theme.currentTheme.danger;
            }),
            data: res.map((item) => {
              return item.values[3];
            }),
          },
        ],
      };
      context.commit("setOverallStatus", statusChart);
    } catch (e) {
      LoggerService.error(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
};
