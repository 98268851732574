import { render, staticRenderFns } from "./Media.vue?vue&type=template&id=7e98c0da&scoped=true&"
import script from "./Media.vue?vue&type=script&lang=ts&"
export * from "./Media.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e98c0da",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable,VDialog,VFileInput,VForm,VOverlay,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e98c0da')) {
      api.createRecord('7e98c0da', component.options)
    } else {
      api.reload('7e98c0da', component.options)
    }
    module.hot.accept("./Media.vue?vue&type=template&id=7e98c0da&scoped=true&", function () {
      api.rerender('7e98c0da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/digitalSignage/Media.vue"
export default component.exports