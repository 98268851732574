import {
  Timezone,
  TimezoneItem,
  TIMEZONES_ITEMS,
  TIMEZONES_RAW,
} from "@/models/timezone";
import cloneDeep from "lodash/cloneDeep";
import store from "@/store";
import PreferenceUtil from "@/utils/preferenceUtil";
import { Settings } from "luxon";

export default {
  /**
   * Generate timezones for the app
   * https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json
   * Copy/past content in TIMEZONES_RAW, launch function and set the result in TIMEZONES
   */
  generateTimezones(): void {
    const timezones = cloneDeep(TIMEZONES_RAW);
    const items: Array<TimezoneItem> = [] as Array<any>;
    let utc = "";
    timezones.map((timezone) => {
      items.push({
        header: timezone.value + " (" + timezone.abbr + ")",
      });
      utc = timezone.text.substring(1, timezone.text.indexOf(")"));
      timezone.utc.map((utcItem: string) => {
        items.push({
          value: utcItem,
          offset: timezone.offset,
          label: utcItem.replaceAll("_", " "),
          utc: utc,
        });
      });
      items.push({
        divider: true,
      });
    });
    // console.log(items);
  },

  /**
   * Init app timezone
   */
  initTimezone(): void {
    let timezone = PreferenceUtil.getPreference("timezone");
    if (!timezone) {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      timezone = TIMEZONES_ITEMS.find((timezone: TimezoneItem) => {
        return (timezone as Timezone).value === localTimezone;
      });
    }
    if (timezone) {
      this.setTimezone(timezone as Timezone);
    }
  },

  /**
   * Set timezone
   * @param timezone
   * @param syncState
   */
  setTimezone(timezone: Timezone, syncState = true): void {
    PreferenceUtil.setPreference("timezone", timezone);
    Settings.defaultZoneName = timezone.value;
    if (syncState) {
      store.commit("session/setTimezone", timezone);
    }
  },
};
