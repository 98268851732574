

















































































































































































import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";
import FileUploader from "@/components/shared/FileUploader.vue";
import { FileFormatted } from "@/models/file";
import VaTextField from "@/components/atoms/VaTextField.vue";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "HomeLauncherConfiguration",
  components: { VaTextField, FileUploader, VaSwitch },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
    };
  },
  methods: {
    uploadWorkspaceBackground(background: FileFormatted) {
      this.fields.WorkspaceBackground = background;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange();
    },
  },
});
