import Vue, { PropType } from "vue";
import { FieldValues, groupsDecoder, Policy } from "@/models/policy";
import cloneDeep from "lodash/cloneDeep";
import PolicyService from "@/services/policyService";
import { ValidatableForm } from "@/components/mixins/validationMixin";

export default Vue.extend({
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
    };
  },
  props: {
    value: {
      type: Object as PropType<{ policy: Policy; valid: boolean }>,
      required: true,
    },
  },
  methods: {
    validate(): void {
      (this.$refs.nestedForm as ValidatableForm).validate();
    },
    onChangeValidation(valid: boolean): void {
      const res = cloneDeep(this.value);
      res.valid = valid;
      this.$emit("input", res);
    },
    onChange(type = "string"): void {
      const res = cloneDeep(this.value);
      for (const key in this.fields) {
        if (!res.policy.fields) {
          res.policy.fields = {};
        }
        if (res.policy.fields[key]) {
          //edit
          res.policy.fields[key].keyValue = this.fields[key];
        } else {
          //creation
          res.policy.fields[key] = {
            keyName: key,
            keyValue: this.fields[key],
            keyType: type, //hack: on creation we don't know the key type, BE don't use it
          };
        }
      }
      this.$emit("input", res);
    },
  },
  async beforeMount() {
    if (this.value.policy && !this.value.policy.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const groupId = groupsDecoder[this.value.policy.type];
      const defaults = await PolicyService.getDefaults(groupId);
      this.fields = await PolicyService.extractFields(defaults);
    }
  },
  watch: {
    value: {
      handler: async function (val) {
        if (val.policy.fields && val.policy.id) {
          this.fields = await PolicyService.extractFields(val.policy.fields);
        }
      },
      deep: true,
    },
  },
});
