import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "@/store";
import PlaylistService from "@/services/playlistService";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import * as listable from "./listableModule";
import cloneDeep from "lodash/cloneDeep";
import {
  Playlist,
  PlaylistDeserializer,
  emptyPlaylist,
} from "@/models/playlist";
import router from "@/router";

export interface PlaylistState extends listable.ListableState {
  currenPlaylist?: Playlist;
  playlists: Playlist[];
  editing: boolean;
  allPlaylist: Playlist[];
}

type PlaylistContext = ActionContext<PlaylistState, RootState>;

export const namespaced = true;

const initialState = {
  ...listable.state,
  ...{
    playlists: [],
    currenPlaylist: cloneDeep(emptyPlaylist),
    editing: false,
    allPlaylist: [],
  },
};

export const state = (): PlaylistState => cloneDeep(initialState);

export const getters: GetterTree<PlaylistState, RootState> = {
  ...listable.getters,
  ...{
    currenPlaylist: (state): Playlist | undefined => {
      return state.currenPlaylist;
    },
    playlists: (state): Playlist[] => {
      return state.playlists;
    },
    editing: (state): boolean => {
      return state.editing;
    },
    allPlaylist: (state): Playlist[] => {
      return state.allPlaylist;
    },
  },
};
export const mutations: MutationTree<PlaylistState> = {
  ...listable.mutations,
  ...{
    setCurrentPlaylist(state: PlaylistState, playlist: Playlist) {
      state.currenPlaylist = playlist;
    },
    resetCurrentPlaylist(state: PlaylistState) {
      state.currenPlaylist = cloneDeep(emptyPlaylist);
    },
    setPlaylist(state: PlaylistState, playlists: Playlist[]) {
      state.playlists = playlists;
    },
    updatePlaylist(state: PlaylistState, currenPlaylist: Playlist) {
      state.playlists = state.playlists.map((i) => {
        if (i.playlistId === currenPlaylist.playlistId) {
          return currenPlaylist;
        }
        return i;
      });
    },
    deletePlaylist(state: PlaylistState, currenPlaylist: Playlist) {
      state.playlists = state.playlists.filter((i) => {
        return i.playlistId !== currenPlaylist.playlistId;
      });
    },
    addPlaylist(state: PlaylistState, currenPlaylist: Playlist) {
      state.playlists.push(currenPlaylist);
    },
    setEditing(state: PlaylistState, editing: boolean) {
      state.editing = editing;
    },
  },
};
export const actions: ActionTree<PlaylistState, RootState> = {
  ...listable.actions,
  ...{
    async reset(context: PlaylistContext): Promise<void> {
      const s = cloneDeep(initialState);
      context.state.playlists = s.playlists;
      context.state.currenPlaylist = s.currenPlaylist;
      context.state.editing = s.editing;
      context.dispatch("resetListable");
    },
    async fetchList(context: PlaylistContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await PlaylistService.search(
          context.state.queryOptions,
          context.state.searchString
        );
        context.commit("setPlaylist", res.items.map(PlaylistDeserializer));
        context.commit("setTotalItems", res.count);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
    async getPlaylist(context: PlaylistContext, id: string): Promise<void> {
      context.commit("setLoading", true);
      try {
        context.state.currenPlaylist = await PlaylistService.get(id);
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },

    async deletePlaylist(context: PlaylistContext): Promise<void> {
      await context.commit(
        "notifications/displayConfirmDialog",
        {
          visible: true,
          title: i18n.t("ds.deletePlaylist"),
          description: `${i18n.t("ds.deletePlaylistConfirm")} "${
            context.state.currenPlaylist?.name
          }"`,
          callback: async () => {
            context.commit("setLoading", true);
            const pl = context.state.currenPlaylist;
            if (pl && pl.playlistId) {
              try {
                await PlaylistService.delete(pl.playlistId);
                context.commit("deletePlaylist", pl);
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: i18n.t("ds.deletePlaylistSuccess"),
                    type: "success",
                  },
                  { root: true }
                );
                router.push(`/digital-signage/playlists`);
              } catch (e) {
                logger.error(e);
                context.commit(
                  "notifications/displayNotification",
                  {
                    message: e,
                    type: "warining",
                  },
                  { root: true }
                );
              } finally {
                context.commit("setLoading", false);
                context.commit("setEditing", false);
              }
            }
          },
        },
        { root: true }
      );
    },

    async createPlaylist(
      context: PlaylistContext,
      playlist: Playlist
    ): Promise<void> {
      context.commit("setLoading", true);
      // const playlist = context.state.currenPlaylist;
      if (playlist) {
        try {
          const res = await PlaylistService.post(playlist);
          // context.dispatch("fetchList");
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("ds.playlistCreated"),
              type: "success",
            },
            { root: true }
          );
          router.push(`/digital-signage/playlists/${res.data}`);
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
          context.commit("setEditing", false);
        }
      }
    },

    async updatePlaylist(
      context: PlaylistContext,
      playlist: Playlist
    ): Promise<void> {
      context.commit("setLoading", true);
      if (playlist) {
        try {
          await PlaylistService.put(playlist);
          context.commit("updatePlaylist", playlist);
          context.commit(
            "notifications/displayNotification",
            {
              message: i18n.t("ds.playlistUpdated"),
              type: "success",
            },
            { root: true }
          );
          context.state.currenPlaylist = playlist;
        } catch (e) {
          logger.error(e);
          context.commit(
            "notifications/displayNotification",
            {
              message: e,
              type: "error",
            },
            { root: true }
          );
        } finally {
          context.commit("setLoading", false);
          context.commit("setEditing", false);
        }
      }
    },

    async fetchAll(context: PlaylistContext): Promise<void> {
      context.commit("setLoading", true);
      try {
        const res = await PlaylistService.fetch();
        context.state.allPlaylist = res.sort(
          (a, b) => b.creationDate.getTime() - a.creationDate.getTime()
        );
      } catch (e) {
        logger.error(e);
      } finally {
        context.commit("setLoading", false);
      }
    },
  },
};
