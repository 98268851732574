var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.items,
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["media/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["media/loading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["media/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: { title: _vm.$t("ds.media"), cta: _vm.$t("ds.media") },
                  on: { ctaClick: _vm.onCreate },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.downloadUrl",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "a",
                  { attrs: { href: item.downloadUrl, target: "_blank" } },
                  [_vm._v("Download")]
                )
              ]
            }
          },
          {
            key: "item.sizeInBytes",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("bytesToSize")(item.sizeInBytes)) + " "
                )
              ]
            }
          },
          {
            key: "item.uploadDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("date2string")(item.uploadDate)) + " "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-details-button", {
                  on: {
                    click: function($event) {
                      return _vm.onEdit(item)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "va-dialog", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "va-card",
            [
              _c("v-overlay", {
                attrs: {
                  absolute: true,
                  value: _vm.$store.getters["media/loading"] == true
                }
              }),
              _c("div", { staticClass: "va-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.update
                        ? _vm.$t("ds.newMedia")
                        : _vm.$t("ds.editMedia")
                    ) +
                    " "
                )
              ]),
              _vm.currentMedia
                ? _c(
                    "v-form",
                    {
                      ref: "editMediaForm",
                      attrs: {
                        "lazy-validation": "",
                        onSubmit: "return false;"
                      },
                      on: { submit: _vm.onSave },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      !_vm.update
                        ? _c("v-file-input", {
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("ds.mediaFile"),
                              outlined: "",
                              "prepend-icon": "vi vi-folder-play",
                              accept: ["image/*", "video/*"]
                            },
                            model: {
                              value: _vm.newfile,
                              callback: function($$v) {
                                _vm.newfile = $$v
                              },
                              expression: "newfile"
                            }
                          })
                        : _vm._e(),
                      _vm.update
                        ? _c("va-text-field", {
                            staticClass: "mb-4",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("common.name")
                            },
                            model: {
                              value: _vm.currentMedia.name,
                              callback: function($$v) {
                                _vm.$set(_vm.currentMedia, "name", $$v)
                              },
                              expression: "currentMedia.name"
                            }
                          })
                        : _vm._e(),
                      _c("va-text-area", {
                        staticClass: "mb-4",
                        attrs: { label: _vm.$t("common.notes") },
                        model: {
                          value: _vm.currentMedia.note,
                          callback: function($$v) {
                            _vm.$set(_vm.currentMedia, "note", $$v)
                          },
                          expression: "currentMedia.note"
                        }
                      }),
                      _vm.update
                        ? _c(
                            "div",
                            { staticClass: "mb-4 border-a pa-4" },
                            [
                              _c("div", { staticClass: "va-subtitle-s" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("ds.playlistThatUse")) +
                                    " "
                                )
                              ]),
                              _c("v-simple-table", {
                                attrs: {
                                  "fixed-header": "",
                                  height: "140px",
                                  dense: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("common.name")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.description"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.currentMedia.playlists,
                                              function(item) {
                                                return _c(
                                                  "tr",
                                                  { key: item.name },
                                                  [
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.name))
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.description)
                                                      )
                                                    ])
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2216807864
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.update
                            ? _c("va-button", {
                                attrs: {
                                  cta: _vm.$t("common.delete"),
                                  color: "error"
                                },
                                on: { click: _vm.onDelete }
                              })
                            : _vm._e(),
                          _c("va-button", {
                            staticClass: "mr-4 ml-auto",
                            attrs: {
                              color: "secondary",
                              type: "outline",
                              loading: _vm.$store.getters["media/loading"],
                              cta: _vm.$t("common.dismiss")
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          }),
                          _c("va-button", {
                            attrs: {
                              submit: "",
                              disabled: !_vm.valid,
                              loading: _vm.$store.getters["media/loading"],
                              cta: _vm.$t("common.submit")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }