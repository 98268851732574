






















































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import PerformanceChart from "@/components/dashboard/performance/PerformanceChart.vue";
import VaInfoRow from "@/components/molecules/VaInfoRow.vue";
import PerformanceService from "@/services/performanceService";
import {
  PERFORMANCE_RANGE_DATA,
  PERFORMANCE_STATS_DATA,
  PerformanceChartRawData,
  PerformanceRange,
  PerformanceRangeData,
} from "@/models/performance";
import PerformanceUtil from "@/utils/performanceUtil";
import cloneDeep from "lodash/cloneDeep";
import { ChartData } from "@/models/analytics";
import VaButton from "@/components/atoms/VaButton.vue";

export default Vue.extend({
  name: "Performance",
  components: { VaButton, VaInfoRow, PerformanceChart },
  data() {
    return {
      range: cloneDeep(PERFORMANCE_RANGE_DATA[0]),
      ranges: cloneDeep(PERFORMANCE_RANGE_DATA),
      loading: false,
      performanceStats: cloneDeep(PERFORMANCE_STATS_DATA),
      performanceChartData: [] as Array<PerformanceChartRawData>,
      poller: 0,
    };
  },
  beforeMount() {
    this.range = cloneDeep(PERFORMANCE_RANGE_DATA[0]);
  },
  beforeDestroy() {
    clearInterval(this.poller);
  },
  computed: {
    room(): RoomDetail | null {
      return this.$store.getters["room/room"];
    },
    performanceDatasets(): ChartData {
      return PerformanceUtil.buildDatasets(this.performanceChartData);
    },
  },
  methods: {
    attachPoller() {
      this.poller = setInterval(() => {
        this.getPerformance(true);
      }, 60000);
    },
    async getPerformance(silent = false): Promise<void> {
      if (!silent) {
        this.loading = true;
      }
      try {
        this.performanceChartData = await PerformanceService.get(
          this.room?.id as string,
          (this.range as PerformanceRangeData).value
        );
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "Performance", "getPerformance");
      } finally {
        if (!silent) {
          this.loading = false;
        }
      }
    },
  },
  watch: {
    room: function (newVal, oldval) {
      if (oldval == undefined) {
        const initialRange = newVal?.online
          ? PerformanceRange.OneH
          : PerformanceRange.Weekly;
        this.range = PERFORMANCE_RANGE_DATA.find((range) => {
          return range.value === initialRange;
        }) as PerformanceRangeData;
      }
    },
    range: async function (newRange): Promise<void> {
      if (newRange.value === PerformanceRange.OneH) {
        this.attachPoller();
      } else {
        clearInterval(this.poller);
      }
      await this.getPerformance();
    },
  },
});
