







































import Vue, { VueConstructor } from "vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "LanguageConfiguration",
  components: {},
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      languagesOptions: [
        //TODO: MOVE ME TO DEDICATED SERVICE?
        {
          value: "en-US",
          text: this.$t("common.english") as string,
          icon: require("@/assets/images/flags/en.png"),
        },
        {
          value: "it-IT",
          text: this.$t("common.italian") as string,
          icon: require("@/assets/images/flags/it.png"),
        },
        {
          value: "fr-FR",
          text: this.$t("common.french") as string,
          icon: require("@/assets/images/flags/fr.png"),
        },
        {
          value: "es-ES",
          text: this.$t("common.spanish") as string,
          icon: require("@/assets/images/flags/es.png"),
        },
        {
          value: "ru-RU",
          text: this.$t("common.russian") as string,
          icon: require("@/assets/images/flags/ru.png"),
        },
      ],
    };
  },
});
