import i18n from "@/i18n";
import fileService from "@/services/fileService";
import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";
import { ShortPlaylist, ShortPlaylistDeserializer } from "@/models/playlist";

export interface Media {
  companyMediaId: string;
  name: string;
  fileType: string;
  mimeType?: string;
  extension: string;
  sizeInBytes: number;
  note: string;
  downloadUrl: string;
  uploadDate: Date;
  playlists: ShortPlaylist[];
  duration: number;
  mute: boolean;
}

export const emptyMedia: Media = {
  companyMediaId: "",
  name: "",
  fileType: "",
  extension: "",
  sizeInBytes: 0,
  note: "",
  downloadUrl: "",
  uploadDate: new Date(),
  playlists: [],
  duration: 3,
  mute: true,
};

export const mediaConfig = {
  companyMediaId: {
    dtoName: "companyMediaId",
    sortable: false,
    filterable: false,
    label: i18n.t("common.id"),
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
  },
  extension: {
    dtoName: "type",
    sortable: true,
    filterable: true,
    label: i18n.t("ds.extension"),
  },
  sizeInBytes: {
    dtoName: "sizeInBytes",
    sortable: true,
    filterable: false,
    label: i18n.t("ds.fileSize"),
  },
  fileType: {
    dtoName: "fileType",
    sortable: false,
    filterable: false,
    label: i18n.t("ds.fileType"),
  },
  note: {
    dtoName: "note",
    sortable: true,
    filterable: true,
    label: i18n.t("common.notes"),
  },
  uploadDate: {
    dtoName: "uploadDate",
    sortable: true,
    filterable: false,
    label: i18n.t("common.creationDate"),
  },
  downloadUrl: {
    dtoName: "downloadUrl",
    sortable: false,
    filterable: false,
    label: i18n.t("common.download"),
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function MediaDeserializer(input: any): Media {
  return {
    companyMediaId: input.companyMediaId || "",
    name: input.name || "",
    extension: input.type || "",
    fileType: input.fileType || fileService.extension2filetype(input.type),
    mimeType: input.mimeType || fileService.extension2mimetype(input.type),
    sizeInBytes: input.sizeInBytes || 0,
    note: input.note || "",
    downloadUrl: input.downloadUrl || "",
    uploadDate: new Date(input.uploadDate),
    playlists: input.playlists
      ? input.playlists.map(ShortPlaylistDeserializer)
      : [],
    duration: input.duration || 3,
    mute: input.mute == undefined ? true : input.mute,
  };
}

export function MediaSerializer(input: Media): unknown {
  return {
    companyMediaId: input.companyMediaId,
    name: input.name,
    type: input.extension,
    fileType: input.fileType,
    mimeType: input.mimeType,
    // sizeInBytes: input.sizeInBytes,
    note: input.note,
    downloadUrl: input.downloadUrl,
    duration: input.duration,
    mute: input.mute,
    // uploadDate: input.uploadDate.getTime(),
    // playlists: input.playlists
  };
}

export function MediaSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, mediaConfig),
    filterBy: { values: FilterBySerializer(mediaConfig, searchString) },
  };
}
