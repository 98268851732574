var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room && _vm.range
    ? _c("div", { attrs: { id: "performance" } }, [
        _c("div", { staticClass: "va-subtitle-s" }, [
          _vm._v(_vm._s(_vm.$t("common.liveData")))
        ]),
        _c(
          "section",
          { attrs: { id: "performance-range" } },
          [
            _c(
              "va-info-row",
              { attrs: { label: _vm.$t("common.range") } },
              [
                _c(
                  "v-menu",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g({}, on),
                                [
                                  _c("va-button", {
                                    attrs: {
                                      size: "s",
                                      loading: _vm.loading,
                                      disabled: _vm.loading,
                                      cta: _vm.range.label,
                                      "icon-position": "append",
                                      icon: "vi vi-chevron-down"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1211523641
                    )
                  },
                  [
                    _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      _vm._l(_vm.ranges, function(r, index) {
                        return _c(
                          "v-list-item",
                          {
                            key: index,
                            attrs: {
                              disabled: !_vm.room.online && r.value !== 5
                            },
                            on: {
                              click: function($event) {
                                _vm.range = r
                              }
                            }
                          },
                          [_c("v-list-item-title", [_vm._v(_vm._s(r.label))])],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "va-info-row",
              { attrs: { label: _vm.$t("common.samplingRate") } },
              [_vm._v(_vm._s(_vm.range.sampling.rate) + " ")]
            ),
            _c(
              "va-info-row",
              { attrs: { label: _vm.$t("common.samplings") } },
              [_vm._v(_vm._s(_vm.range.sampling.count.toString()) + " ")]
            ),
            _c(
              "va-info-row",
              { attrs: { label: _vm.$t("common.realtimeUpdate") } },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      small: "",
                      color: _vm.range.value === 1 ? "success" : "error"
                    }
                  },
                  [_vm._v("mdi-checkbox-blank-circle ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "section",
          { attrs: { id: "performance-chart" } },
          [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center pa-10" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "accent" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.loading && _vm.performanceDatasets
              ? _c("performance-chart", {
                  attrs: { data: _vm.performanceDatasets }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }