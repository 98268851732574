var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.workspace")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c("va-switch", {
            attrs: { text: _vm.$t("policiesFields.ShowCustomBackground") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.ShowCustomBackground,
              callback: function($$v) {
                _vm.$set(_vm.fields, "ShowCustomBackground", $$v)
              },
              expression: "fields.ShowCustomBackground"
            }
          }),
          _vm.fields.ShowCustomBackground
            ? _c("file-uploader", {
                staticClass: "mt-4",
                attrs: {
                  files: _vm.fields.WorkspaceBackground
                    ? [_vm.fields.WorkspaceBackground]
                    : [],
                  title: _vm.$t("policiesFields.WorkspaceBackground"),
                  accept: [".png", ".jpg"],
                  "file-type": 3
                },
                on: { upload: _vm.uploadWorkspaceBackground }
              })
            : _vm._e(),
          _c("va-switch", {
            attrs: { text: _vm.$t("policiesFields.UsePresenter") },
            on: { input: _vm.onChange },
            model: {
              value: _vm.fields.UsePresenter,
              callback: function($$v) {
                _vm.$set(_vm.fields, "UsePresenter", $$v)
              },
              expression: "fields.UsePresenter"
            }
          }),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.DocumentQuality")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.DocumentQuality,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "DocumentQuality", $$v)
                      },
                      expression: "fields.DocumentQuality"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.MaxElementPerPage")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.MaxElementPerPage,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "MaxElementPerPage", $$v)
                      },
                      expression: "fields.MaxElementPerPage"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t(
                        "policiesFields.ShowAllStorageDevicesInFileManager"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowAllStorageDevicesInFileManager,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "ShowAllStorageDevicesInFileManager",
                          $$v
                        )
                      },
                      expression: "fields.ShowAllStorageDevicesInFileManager"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t(
                        "policiesFields.ShowMyDocumentsInFileManager"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowMyDocumentsInFileManager,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "ShowMyDocumentsInFileManager",
                          $$v
                        )
                      },
                      expression: "fields.ShowMyDocumentsInFileManager"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t(
                        "policiesFields.ShowMyDownloadsInFileManager"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowMyDownloadsInFileManager,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "ShowMyDownloadsInFileManager",
                          $$v
                        )
                      },
                      expression: "fields.ShowMyDownloadsInFileManager"
                    }
                  }),
                  _c("va-switch", {
                    attrs: { text: _vm.$t("policiesFields.ShowEmailInRecap") },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowEmailInRecap,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ShowEmailInRecap", $$v)
                      },
                      expression: "fields.ShowEmailInRecap"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t("policiesFields.BrowserDefaultUrl")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.BrowserDefaultUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "BrowserDefaultUrl", $$v)
                      },
                      expression: "fields.BrowserDefaultUrl"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.MaxBrowserPerPage")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.MaxBrowserPerPage,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "MaxBrowserPerPage", $$v)
                      },
                      expression: "fields.MaxBrowserPerPage"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t(
                        "policiesFields.ShowAllStorageDevicesInRecap"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowAllStorageDevicesInRecap,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "ShowAllStorageDevicesInRecap",
                          $$v
                        )
                      },
                      expression: "fields.ShowAllStorageDevicesInRecap"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.ShowMyDocumentsInRecap")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowMyDocumentsInRecap,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ShowMyDocumentsInRecap", $$v)
                      },
                      expression: "fields.ShowMyDocumentsInRecap"
                    }
                  }),
                  _c("va-switch", {
                    attrs: { text: _vm.$t("policiesFields.ShowCloudsInRecap") },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowCloudsInRecap,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ShowCloudsInRecap", $$v)
                      },
                      expression: "fields.ShowCloudsInRecap"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.ShowPrinterInRecap")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowPrinterInRecap,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ShowPrinterInRecap", $$v)
                      },
                      expression: "fields.ShowPrinterInRecap"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.PenDefaultSize")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.PenDefaultSize,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "PenDefaultSize", $$v)
                      },
                      expression: "fields.PenDefaultSize"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.MarkerDefaultSize")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.MarkerDefaultSize,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "MarkerDefaultSize", $$v)
                      },
                      expression: "fields.MarkerDefaultSize"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.HighlighterDefaultSize")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.HighlighterDefaultSize,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "HighlighterDefaultSize", $$v)
                      },
                      expression: "fields.HighlighterDefaultSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.EraserDefaultSize")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.EraserDefaultSize,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "EraserDefaultSize", $$v)
                      },
                      expression: "fields.EraserDefaultSize"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t("policiesFields.BigEraserDefaultSize")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.BigEraserDefaultSize,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "BigEraserDefaultSize", $$v)
                      },
                      expression: "fields.BigEraserDefaultSize"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.ShowConferencePinned")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ShowConferencePinned,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ShowConferencePinned", $$v)
                      },
                      expression: "fields.ShowConferencePinned"
                    }
                  }),
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.AutoScreenSharingEnabled")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.AutoScreenSharingWhenOpenWhiteboard,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "AutoScreenSharingWhenOpenWhiteboard",
                          $$v
                        )
                      },
                      expression: "fields.AutoScreenSharingWhenOpenWhiteboard"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: 6 } },
                [
                  _c("va-switch", {
                    attrs: {
                      text: _vm.$t("policiesFields.ScreenshotInFullscreen")
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.ScreenshotInFullscreen,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "ScreenshotInFullscreen", $$v)
                      },
                      expression: "fields.ScreenshotInFullscreen"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }