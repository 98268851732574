var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("va-button", {
    attrs: {
      icon: "vi vi-c-info",
      "icon-position": "append",
      size: "s",
      cta: _vm.$t("common.details")
    },
    on: {
      click: function() {
        return _vm.$emit("click")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }