import Vue from "vue";
import { HeaderTable, OptionsTable, TableConfig } from "@/models/table";
import router from "@/router";

export interface DataTableMixin extends Vue {
  resetQueryOptions: () => void;
  generateHeaders: (
    tableConfig: TableConfig,
    actions?: boolean
  ) => HeaderTable[];
  searchString: string;
  groupOptions: OptionsTable;
}

export default Vue.extend({
  data() {
    return {
      storeName: "", //EXTEND WITH ACTUAL STORE NAME
      actionHeaders: [
        {
          value: "actions",
          text: this.$i18n.t("common.actions"),
          sortable: false,
          filterable: false,
          width: 100,
        },
      ] as HeaderTable[],
    };
  },
  async beforeMount() {
    this.resetQueryOptions();
  },
  methods: {
    generateHeaders(tableConfig: TableConfig, actions = true): HeaderTable[] {
      const headers = Object.keys(tableConfig)
        .filter((key: string) => {
          return !tableConfig[key].hideColumn;
        })
        .map((key: string) => {
          return {
            text: tableConfig[key].label,
            value: key,
            sortable: tableConfig[key].sortable,
            filterable: tableConfig[key].sortable,
          };
        });
      if (actions) {
        return headers.concat(this.$data.actionHeaders);
      }
      return headers;
    },
    resetQueryOptions() {
      if (!(router.currentRoute.query?.preserveQuery == "true")) {
        this.$store.commit(`${this.storeName}/resetQueryOptions`);
      }
    },
  },
  computed: {
    groupOptions: {
      get(): OptionsTable {
        return this.$store.getters[`${this.storeName}/queryOptions`];
      },
      set(opt: OptionsTable): void {
        this.$store.dispatch(`${this.storeName}/updateQueryOptions`, opt);
      },
    },
    searchString: {
      get(): string {
        return this.$store.getters[`${this.storeName}/searchString`];
      },
      set(str: string): void {
        this.$store.dispatch(`${this.storeName}/updateSearchString`, str);
      },
    },
  },
});
