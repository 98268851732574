var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "policies-chooser" } },
    [
      _vm.showAlert
        ? _c(
            "va-notification",
            { staticClass: "mb-6", attrs: { type: "warning" } },
            [_vm._v(" " + _vm._s(_vm.$t("policiesChooser.applyAlert")) + " ")]
          )
        : _vm._e(),
      _vm._l(_vm.groupedPolicies, function(groupedPolicy, index) {
        return _c("div", { key: index }, [
          _vm.$store.getters["session/isAdmin"] ||
          groupedPolicy.groupName == "Appearance"
            ? _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-select", {
                    attrs: {
                      label: groupedPolicy.groupName,
                      outlined: "",
                      dense: "",
                      loading: _vm.processing === groupedPolicy.policyGroupId,
                      disabled:
                        _vm.processing === groupedPolicy.policyGroupId ||
                        _vm.disabled ||
                        (!_vm.$store.getters["session/isAdmin"] &&
                          groupedPolicy.groupName != "Appearance"),
                      items: groupedPolicy.groupPolicies,
                      "item-text": "name",
                      "item-value": "policyId"
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange(
                          $event,
                          groupedPolicy.policyGroupId,
                          index
                        )
                      },
                      focusin: function($event) {
                        return _vm.onFocus(index)
                      }
                    },
                    model: {
                      value: _vm.model[index].value,
                      callback: function($$v) {
                        _vm.$set(_vm.model[index], "value", $$v)
                      },
                      expression: "model[index].value"
                    }
                  }),
                  !_vm.hideIcons
                    ? _c("va-icon", {
                        staticClass: "ml-2 mt-2",
                        attrs: {
                          size: "m",
                          color:
                            _vm.model[index].isIn === true
                              ? "success"
                              : _vm.model[index].isIn === false
                              ? "warning"
                              : "secondary",
                          icon:
                            _vm.model[index].isIn === true
                              ? "vi vi-c-check"
                              : _vm.model[index].isIn === false
                              ? "vi vi-c-warning"
                              : "vi vi-c-delete"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      }),
      _vm.isRoom
        ? _c(
            "div",
            { staticClass: "d-flex flex-row-reverse mt-2" },
            [
              _c("va-button", {
                attrs: {
                  small: "",
                  disabled: _vm.processingAll || _vm.disabled,
                  loading: _vm.processingAll,
                  cta: _vm.$t("common.applyAll")
                },
                on: { click: _vm.applyAll }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }