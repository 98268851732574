var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room
    ? _c(
        "div",
        [
          _c("div", { staticClass: "d-flex justify-space-between" }, [
            _c("div", { staticClass: "va-subtitle font-bold" }, [
              _vm._v(_vm._s(_vm.$t("common.information")))
            ]),
            _c("div", { staticClass: "va-subtitle font-bold grey--text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.room.isEssential
                      ? _vm.$t("common.roomEssential")
                      : _vm.$t("common.roomPro")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.details")))
                  ]),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.licenseKey") } },
                    [_vm._v(_vm._s(_vm.room.licenseKey) + " ")]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.overallState") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _vm.overallStateData.id === 3
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "mr-4",
                                  attrs: { small: "", color: "error" }
                                },
                                [_vm._v("mdi-alert ")]
                              )
                            : _vm._e(),
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.overallStateData.label))
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.overallStateData.color
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.maintenance") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.room.isMaintenanceModeEnabled
                                  ? _vm.$t("common.maintenance")
                                  : _vm.$t("common.available")
                              )
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.room.isMaintenanceModeEnabled
                                  ? "purple"
                                  : "secondary"
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  false
                    ? _c(
                        "va-info-row",
                        {
                          attrs: { label: _vm.$t("common.overallPerformance") }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v(
                                  _vm._s(_vm.overallPerformanceStatusData.label)
                                )
                              ]),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color:
                                      _vm.overallPerformanceStatusData.color
                                  }
                                },
                                [_vm._v("vi vi-shape-oval ")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.osVersion") } },
                    [_vm._v(_vm._s(_vm.room.windowVersion) + " ")]
                  ),
                  _c(
                    "va-info-row",
                    {
                      attrs: { label: _vm.$t("common.valareaRoomAppVersion") }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.room.version) + " "),
                      _vm.room.podReleaseType !== ""
                        ? _c("span", [
                            _vm._v(
                              " - " + _vm._s(_vm.room.podReleaseType) + " "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "va-info-row",
                    {
                      attrs: { label: _vm.$t("common.valareaRoomAgentVersion") }
                    },
                    [_vm._v(_vm._s(_vm.room.workerVersion) + " ")]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.upTime") } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm._f("duration")(_vm.room.upTime)) + " "
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.group") } },
                    [
                      _c("va-chip", {
                        attrs: {
                          text: _vm.room.group.isDefault
                            ? _vm.$t("common.unassigned")
                            : _vm.room.group.name,
                          size: "s"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.tags") } },
                    [
                      _vm.room.tags.length > 0
                        ? _c(
                            "div",
                            { staticClass: "text-right" },
                            _vm._l(_vm.room.tags, function(tag, index) {
                              return _c("va-chip", {
                                key: index,
                                staticClass: "ml-1 mb-1",
                                attrs: { text: tag.name, size: "s" }
                              })
                            }),
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("common.noTagsApplied")) + " "
                            )
                          ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.lastStatus")))
                  ]),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.lastStatus") } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatDateTime")(_vm.room.lastUpdate)
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.meetingsInProgress") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.room.hasOngoingMeeting
                                  ? _vm.$t("common.inProgress")
                                  : _vm.$t("common.available")
                              )
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.room.hasOngoingMeeting
                                  ? "primary"
                                  : "secondary"
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.camera") } },
                    [
                      _vm.room.isEssential
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center grey--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common.unavailable")) + " "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.room.camera
                                      ? _vm.$t("common.enabled")
                                      : _vm.$t("common.disabled")
                                  )
                                )
                              ]),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color: _vm.room.camera ? "success" : "error"
                                  }
                                },
                                [_vm._v("vi vi-shape-oval ")]
                              )
                            ],
                            1
                          )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.wirelessPresentation") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.room.airServer
                                  ? _vm.$t("common.enabled")
                                  : _vm.$t("common.disabled")
                              )
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.room.airServer ? "success" : "error"
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    {
                      attrs: { label: _vm.$t("common.valareaRoomApplication") }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.room.status
                                  ? _vm.$t("common.enabled")
                                  : _vm.$t("common.disabled")
                              )
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.room.status ? "success" : "error"
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.microphone") } },
                    [
                      _vm.room.isEssential
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center grey--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common.unavailable")) + " "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.room.microphone
                                      ? _vm.$t("common.enabled")
                                      : _vm.$t("common.disabled")
                                  )
                                )
                              ]),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    color: _vm.room.microphone
                                      ? "success"
                                      : "error"
                                  }
                                },
                                [_vm._v("vi vi-shape-oval ")]
                              )
                            ],
                            1
                          )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.speakers") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.room.volume
                                  ? _vm.$t("common.enabled")
                                  : _vm.$t("common.disabled")
                              )
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.room.volume ? "success" : "error"
                              }
                            },
                            [_vm._v("vi vi-shape-oval ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.microphoneVolume") } },
                    [
                      _vm.room.isEssential
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-center grey--text" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common.unavailable")) + " "
                              )
                            ]
                          )
                        : _c("div", [_vm._v(_vm._s(_vm.room.microphoneLevel))])
                    ]
                  ),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.speakersVolume") } },
                    [_vm._v(_vm._s(_vm.room.volumeLevel) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.room.hasUpdate
                    ? _c("va-notification", { attrs: { type: "warning" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-space-between flex-grow-1"
                          },
                          [
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("roomUpdate.new")) + " (")
                              ]),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.room.lastVersion))
                              ]),
                              _c("span", [_vm._v(")")])
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass: "white--text d-flex align-center",
                                attrs: {
                                  to:
                                    "/rooms/" +
                                    _vm.room.id +
                                    "/management#roomupdate"
                                }
                              },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.$t("common.gotoUpdates")))
                                ]),
                                _c("va-icon", {
                                  staticClass: "ml-2",
                                  attrs: {
                                    icon: "vi vi-chevron-right",
                                    color: "white"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "va-subtitle-s" }, [
                    _vm._v(_vm._s(_vm.$t("common.rooms")))
                  ]),
                  _c(
                    "va-info-row",
                    { attrs: { label: _vm.$t("common.peopleCounting") } },
                    [_vm._v(_vm._s(_vm.peopleCountValue) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }