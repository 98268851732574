import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import { RootState } from "./index";
import { User } from "@/models/user";
import AuthService, {
  ForgotPasswordData,
  ResetPasswordData,
  SignUpData,
} from "@/services/authService";
import { TokenData } from "@/models/token";
import { Timezone } from "@/models/timezone";
import { Locale } from "@/models/locale";
import localeUtil from "@/utils/localeUtil";
import timezoneUtil from "@/utils/timezoneUtil";
import Vuetify from "@/plugins/vuetify";
import PreferenceUtil from "@/utils/preferenceUtil";
import router from "@/router";
import logger from "@/services/loggerService";
import i18n from "@/i18n";
import { Company } from "@/models/company";
import clone from "lodash/clone";
import { getBrowserVersion } from "@/services/browserVersionService";
import { isAllowed } from "@/services/rolesService";

export interface SessionState {
  user: User | null;
  token: string | null;
  timezone: Timezone | null;
  locale: Locale | null;
  darkMode: boolean;
  collapseMenu: boolean;
  isLoading: boolean;
  signupSuccess: boolean;
  signupPending: boolean;
  signupError: boolean;
  missingInfoDismissed: boolean;
}

type SessionContext = ActionContext<SessionState, RootState>;

export const namespaced = true;

export const state = (): SessionState => ({
  user: null,
  token: null,
  timezone: null,
  locale: null,
  darkMode: false,
  collapseMenu:
    (localStorage.getItem("collapseMenu") &&
      localStorage.getItem("collapseMenu") === "true") ||
    false,
  isLoading: false,
  signupSuccess: false,
  signupPending: false,
  signupError: false,
  missingInfoDismissed: false,
});

export const getters: GetterTree<SessionState, RootState> = {
  user: (state) => {
    return state.user;
  },
  isAdmin: (state) => {
    return state.user && isAllowed(state.user, ["admin"]);
  },
  isPlaylistEditor: (state) => {
    return state.user && isAllowed(state.user, ["dsAdmin"]);
  },
  missingInfoDismissed: (state): boolean => {
    return state.missingInfoDismissed;
  },
  missingInfo: (state) => {
    return (
      (state.user?.company && !state.user.company.confirmed) ||
      !state.user?.firstName ||
      !state.user?.lastName
    );
  },
  token: (state) => {
    return state.token;
  },
  timezone: (state) => {
    return state.timezone;
  },
  locale: (state) => {
    return state.locale;
  },
  darkMode: (state) => {
    return state.darkMode;
  },
  collapseMenu: (state) => {
    return state.collapseMenu;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  signupSuccess: (state) => {
    return state.signupSuccess;
  },
  signupError: (state) => {
    return state.signupError;
  },
};

export const mutations: MutationTree<SessionState> = {
  setLoading(state: SessionState, loading: boolean) {
    state.isLoading = loading;
  },
  setMissingInfoDismissed(state: SessionState, dismissed: boolean) {
    state.missingInfoDismissed = dismissed;
  },
  setSignupSuccess(state: SessionState, success: boolean) {
    state.signupSuccess = success;
  },
  setSignupPending(state: SessionState, pending: boolean) {
    state.signupPending = pending;
  },
  setSignupError(state: SessionState, error: boolean) {
    state.signupError = error;
  },
  setCurrentUser(state: SessionState, auth: { user: User; token: string }) {
    state.user = auth.user;
    state.token = auth.token;
  },
  updateCurrentUser(state: SessionState, user: User) {
    state.user = user;
  },
  cleanCurrentUser(state: SessionState) {
    state.token = null;
    state.user = null;
  },
  refreshToken(state: SessionState, data: { token: string }) {
    state.token = data.token;
  },
  setTimezone(state: SessionState, timezone: Timezone) {
    state.timezone = timezone;
    timezoneUtil.setTimezone(timezone, false);
  },
  setLocale(state: SessionState, locale: Locale) {
    state.locale = locale;
    localeUtil.setLocale(locale, false);
  },
  toggleCollapseMenu(state: SessionState) {
    state.collapseMenu = !state.collapseMenu;
    localStorage.setItem("collapseMenu", state.collapseMenu.toString());
  },
  toggleDarkMode(state: SessionState) {
    state.darkMode = !state.darkMode;
    Vuetify.framework.theme.dark = state.darkMode;
    PreferenceUtil.setPreference("darkMode", state.darkMode);
    localStorage.setItem("darkMode", state.darkMode.toString());
  },
  setDarkMode(state: SessionState, darkMode: boolean) {
    Vuetify.framework.theme.dark = darkMode;
    state.darkMode = darkMode;
  },
};

export const actions: ActionTree<SessionState, RootState> = {
  async login(context: SessionContext, tokenData: TokenData) {
    AuthService.setTokenData(tokenData);
    const user: User = await AuthService.getUser();
    context.commit("setCurrentUser", {
      user: user,
      token: tokenData.token,
    });
    router.push("/");
  },
  logout(context: SessionContext) {
    /** ------------HACK ------------  **/
    /** need to clear all intervals left by legacy code **/
    // Get a reference to the last interval + 1
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);
    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    /** ------------ END HACK ------------ **/
    context.commit("cleanCurrentUser");
    context.commit("mfa/setMfaToken", "", { root: true });
    context.commit("mfa/setSelectedProvider", undefined, { root: true });
    /** ------------ reset all stores------------ **/
    context.dispatch("room/reset", undefined, { root: true });
    context.dispatch("tag/reset", undefined, { root: true });
    context.dispatch("task/reset", undefined, { root: true });
    context.dispatch("users/reset", undefined, { root: true });
    context.dispatch("policy/reset", undefined, { root: true });
    context.dispatch("podApplication/reset", undefined, { root: true });
    context.dispatch("license/reset", undefined, { root: true });
    context.dispatch("group/reset", undefined, { root: true });
    context.dispatch("cms/reset", undefined, { root: true });
    context.dispatch("app/reset", undefined, { root: true });
    context.dispatch("analytics/reset", undefined, { root: true });
  },
  recoverLogin(context: SessionContext, data: SessionState) {
    context.commit("setCurrentUser", {
      user: data.user,
      token: data.token,
    });
  },
  setRefreshedToken(context: SessionContext, data: TokenData) {
    AuthService.setTokenData(data);
    context.commit("refreshToken", { token: data.token });
  },
  async getCurrentUser(context: SessionContext) {
    const user = await AuthService.getUser();
    context.commit("updateCurrentUser", user);
  },
  async updateCurrentUser(context: SessionContext, data: User) {
    const user = await AuthService.updateUser(data);
    context.commit("updateCurrentUser", user);
  },
  async signUp(context: SessionContext, data: SignUpData) {
    context.commit("setSignupSuccess", false);
    context.commit("setLoading", true);
    try {
      await AuthService.signUp(data);
      context.commit("setSignupSuccess", true);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signUp.success"),
          type: "success",
        },
        { root: true }
      );
    } catch (e) {
      context.commit("setSignupSuccess", false);
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signUp.error"),
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async confirmEmail(
    context: SessionContext,
    data: { email: string; token: string }
  ) {
    if (!context.state.signupPending) {
      context.commit("setSignupPending", true);
      context.commit("setSignupSuccess", false);
      context.commit("setLoading", true);
      try {
        await AuthService.confirmEmail(data.email, data.token);
        context.commit("setSignupSuccess", true);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("signUp.success"),
            type: "success",
          },
          { root: true }
        );
      } catch (e) {
        context.commit("setSignupSuccess", false);
        context.commit("setSignupError", true);
        logger.error(e);
        context.commit(
          "notifications/displayNotification",
          {
            message: i18n.t("signUp.error"),
            type: "error",
          },
          { root: true }
        );
      } finally {
        context.commit("setLoading", false);
        context.commit("setSignupPending", false);
      }
    }
  },
  async resetPassword(context: SessionContext, data: ResetPasswordData) {
    context.commit("setSignupSuccess", false);
    context.commit("setLoading", true);
    try {
      await AuthService.resetPassword(data);
      context.commit("setSignupSuccess", true);
      await context.dispatch(
        "mfa/login",
        {
          password: data.password,
          email: data.email,
          deviceUniqueId: getBrowserVersion(),
          appPlatform: "web",
          grantType: "password",
        },
        { root: true }
      );
    } catch (e) {
      context.commit("setSignupSuccess", false);
      context.commit("setSignupError", true);
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signUp.resetPassError"),
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async forgotPassword(context: SessionContext, email: string) {
    context.commit("setSignupSuccess", false);
    context.commit("setLoading", true);
    try {
      await AuthService.forgotPassword({ email: email } as ForgotPasswordData);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("forgotPassword.success"),
          type: "success",
        },
        { root: true }
      );
      context.commit("setSignupSuccess", true);
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("forgotPassword.error"),
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updateUserCompany(context: SessionContext, data: Company) {
    context.commit("setLoading", true);
    try {
      await AuthService.updateUserCompany(data);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updateCompanySuccess"),
          type: "success",
        },
        { root: true }
      );
      const user = clone(context.state.user) as User;
      data.confirmed = true;
      user.company = data;
      context.commit("updateCurrentUser", user);
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updateCompanyError"),
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updateUserData(
    context: SessionContext,
    data: { firstName: string; lastName: string }
  ) {
    context.commit("setLoading", true);
    try {
      await AuthService.updateUserData(data);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updateUserDataSuccess"),
          type: "success",
        },
        { root: true }
      );
      const user = clone(context.state.user) as User;
      user.firstName = data.firstName;
      user.lastName = data.lastName;
      (user.fullName = data.firstName + " " + data.lastName),
        context.commit("updateCurrentUser", user);
    } catch (e) {
      logger.error(e);
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("profile.updateUserDataError"),
          type: "error",
        },
        { root: true }
      );
    } finally {
      context.commit("setLoading", false);
    }
  },
};
