var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-uploader", class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "preview" },
      [
        _c(
          "div",
          { staticClass: "browse", on: { click: _vm.browseFiles } },
          [
            _vm.processing
              ? _c("v-progress-circular", {
                  attrs: { color: "accent", indeterminate: "" }
                })
              : [
                  _vm.multiple
                    ? _c(
                        "div",
                        { staticClass: "multiple" },
                        [_c("v-icon", [_vm._v("mdi-upload-multiple")])],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "single" },
                        [
                          _vm.uploadedFiles.length < 1
                            ? _c("v-icon", [_vm._v("mdi-upload")])
                            : _vm._e(),
                          _vm.uploadedFiles.length > 0 &&
                          !_vm.imageMimeTypes.includes(
                            _vm.uploadedFiles[0].type
                          )
                            ? _c("v-icon", [
                                _vm._v("mdi-text-box-check-outline")
                              ])
                            : _vm._e(),
                          _vm.uploadedFiles.length > 0 &&
                          _vm.uploadedFiles[0].path &&
                          _vm.imageMimeTypes.includes(_vm.uploadedFiles[0].type)
                            ? _c("v-img", {
                                attrs: {
                                  src: _vm.uploadedFiles[0].path,
                                  "lazy-src": _vm.uploadedFiles[0].path,
                                  height: 78,
                                  width: 78,
                                  "max-height": 78,
                                  "max-width": 78
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                ]
          ],
          2
        ),
        _vm.uploadedFiles.length > 0 && !_vm.multiple && !_vm.disabled
          ? _c(
              "v-btn",
              {
                staticClass: "remove",
                attrs: { "x-small": "", fab: "", color: "error" },
                on: {
                  click: function($event) {
                    return _vm.removeFile()
                  }
                }
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "details" },
      [
        _vm.title ? _c("h5", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
        _vm.accept.length > 0
          ? _c("small", { staticClass: "accept" }, [
              _vm._v(" " + _vm._s(_vm.$t("common.accept")) + ": "),
              _c("span", { staticClass: "font-weight-600" }, [
                _vm._v(_vm._s(_vm.accept.join(",")))
              ]),
              _vm._v(". ")
            ])
          : _vm._e(),
        _c("small", { staticClass: "max-size" }, [
          _vm._v(" " + _vm._s(_vm.$t("common.maxFileSize")) + ": "),
          _c("span", { staticClass: "font-weight-600" }, [
            _vm._v(_vm._s(_vm._f("bytesToSize")(_vm.maxSize)))
          ])
        ]),
        !_vm.multiple &&
        _vm.uploadedFiles.length > 0 &&
        _vm.uploadedFiles[0].name
          ? _c("span", { staticClass: "d-block mt-2" }, [
              _vm._v(" " + _vm._s(_vm.uploadedFiles[0].name) + " ")
            ])
          : _vm._e(),
        _vm.multiple && _vm.uploadedFiles.length > 0
          ? _c(
              "draggable",
              {
                staticClass: "files-uploaded d-flex",
                attrs: { group: "files" },
                on: {
                  end: function($event) {
                    return _vm.$emit("update", _vm.uploadedFiles)
                  }
                },
                model: {
                  value: _vm.uploadedFiles,
                  callback: function($$v) {
                    _vm.uploadedFiles = $$v
                  },
                  expression: "uploadedFiles"
                }
              },
              _vm._l(_vm.uploadedFiles, function(file, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "file-uploaded-preview" },
                  [
                    _c("v-img", {
                      attrs: {
                        src: file.path,
                        "lazy-src": file.path,
                        height: 40,
                        width: 40,
                        "max-height": 40,
                        "max-width": 40
                      },
                      on: {
                        loadstart: function($event) {
                          _vm.ordering = false
                        },
                        load: _vm.onSorted
                      }
                    }),
                    _c("v-progress-circular", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ordering,
                          expression: "ordering"
                        }
                      ],
                      staticClass: "order-loader",
                      attrs: {
                        size: 22,
                        width: 3,
                        indeterminate: "",
                        color: "accent"
                      }
                    }),
                    !_vm.disabled
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "remove",
                            attrs: { "x-small": "", fab: "", color: "error" },
                            on: {
                              click: function($event) {
                                return _vm.removeFile(index)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    ),
    _c("input", {
      ref: "uploader",
      staticClass: "d-none",
      attrs: {
        multiple: _vm.multiple,
        type: "file",
        accept: _vm.accept.join(",")
      },
      on: { change: _vm.uploadFile }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }