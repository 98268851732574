var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "va-title d-flex align-self-center" },
            [
              _c("va-back", {
                attrs: {
                  target: "/rooms/policies",
                  tooltip: _vm.$t("policiesList.back")
                }
              }),
              _c("span", { staticClass: "ml-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.policyId
                        ? _vm.$t("policiesList.newTitle")
                        : _vm.$t("policiesList.editTitle")
                    ) +
                    " "
                ),
                _vm.policyId
                  ? _c("span", [
                      _vm._v("- " + _vm._s(_vm.formState.policy.name))
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "va-card",
            [
              _c(
                "v-form",
                {
                  ref: "updateConfigurationForm",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.formState.valid,
                    callback: function($$v) {
                      _vm.$set(_vm.formState, "valid", $$v)
                    },
                    expression: "formState.valid"
                  }
                },
                [
                  _c("v-select", {
                    staticClass: "mb-4",
                    attrs: {
                      outlined: "",
                      disabled: _vm.policyId !== undefined || !_vm.isAdmin,
                      items: _vm.groups,
                      "item-value": "id",
                      "item-text": "label",
                      label: _vm.$t("common.group"),
                      "hide-details": ""
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.formState.policy.type,
                      callback: function($$v) {
                        _vm.$set(_vm.formState.policy, "type", $$v)
                      },
                      expression: "formState.policy.type"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-4",
                    attrs: {
                      label: _vm.$t("policiesList.name"),
                      rules: [_vm.required],
                      disabled: !_vm.isAdmin
                    },
                    model: {
                      value: _vm.formState.policy.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formState.policy, "name", $$v)
                      },
                      expression: "formState.policy.name"
                    }
                  }),
                  _vm.formState.policy.type === "security"
                    ? _c("security-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "language"
                    ? _c("language-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "update"
                    ? _c("update-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "advanced"
                    ? _c("advanced-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "calendar"
                    ? _c("calendar-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "appearance"
                    ? _c("appearance-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "instantMeetings"
                    ? _c("instant-meetings-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "workspace"
                    ? _c("workspace-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "license"
                    ? _c("license-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "display"
                    ? _c("display-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "presentation"
                    ? _c("presentation-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _vm.formState.policy.type === "applications"
                    ? _c("applications-configuration", {
                        ref: "childForm",
                        model: {
                          value: _vm.formState,
                          callback: function($$v) {
                            _vm.formState = $$v
                          },
                          expression: "formState"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex mt-6" },
                    [
                      _vm.policyId
                        ? _c("va-button", {
                            staticClass: "mr-auto",
                            attrs: {
                              color: "error",
                              loading: _vm.$store.getters["policy/loading"],
                              cta: _vm.$t("common.delete"),
                              disabled: !_vm.isAdmin
                            },
                            on: {
                              click: function($event) {
                                return _vm.$store.dispatch(
                                  "policy/deleteCurrentPolicy"
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _c("va-button", {
                        staticClass: "ml-auto",
                        attrs: {
                          loading: _vm.$store.getters["policy/loading"],
                          disabled:
                            !_vm.formState.valid || !_vm.formState.policy.type,
                          cta: _vm.$t("common.submit")
                        },
                        on: { click: _vm.onSave }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }