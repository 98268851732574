import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult, QueryTable } from "@/models/table";
import {
  Playlist,
  PlaylistDeserializer,
  PlaylistSearchSerializer,
  PlaylistSerializer,
} from "@/models/playlist";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = PlaylistSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.playlist.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async get(id: string): Promise<Playlist> {
    const res = await axios.get(apiService.playlist.baseUrl() + "/" + id);
    return PlaylistDeserializer(res.data);
  },
  async post(playlist: Playlist): Promise<AxiosResponse> {
    const data = PlaylistSerializer(playlist);
    return await axios.post(apiService.playlist.baseUrl(), data);
  },
  async put(playlist: Playlist): Promise<AxiosResponse> {
    const data = PlaylistSerializer(playlist);
    return await axios.put(
      apiService.playlist.baseUrl() + "/" + playlist.playlistId,
      data
    );
  },
  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.playlist.baseUrl() + "/" + id);
  },

  async fetch(): Promise<Playlist[]> {
    //hack limit=0 on thise search means all items
    const data = {
      limit: 0,
      page: 1,
      sortBy: [],
      filterBy: null,
    } as QueryTable;
    const res: AxiosResponse = await axios.post(
      apiService.playlist.searchUrl(),
      data
    );
    return res.data.items.map(PlaylistDeserializer);
  },
  youtubeParser(url: string | undefined): string | false {
    if (!url) return false;
    // const regExp =
    //   /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(live\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const regExp =
      /^.*(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$)).*/;
    const match = url.match(regExp);
    return match && match[3].length == 11 ? match[3] : url;
  },
};
