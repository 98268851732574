





















import Vue, { VueConstructor } from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof policiesMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "DisplayConfiguration",
  components: { VaSwitch },
  mixins: [validationMixin, policiesMixin],
});
