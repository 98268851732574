import {
  PERFORMANCE_STATUS_DATA,
  PerformanceChartFormattedData,
  PerformanceChartRawData,
  PerformanceStatus,
  PerformanceStatusData,
} from "@/models/performance";
import reverse from "lodash/reverse";
import { CHART_PALETTES, ChartData } from "@/models/analytics";
import { DateTime } from "luxon";
import i18n from "@/i18n";

const formatValue = (value: number) => {
  if (value < 0) {
    value = 0;
  }
  if (value > 100) {
    value = 100;
  }
  return Math.ceil(value);
};

export default {
  /*overallStatusData(performance: RoomPerformance): PerformanceStatusData {
    let warning = 0;
    let critical = 0;
    let id = PerformanceStatus.NA;

    if (performance) {
      id = PerformanceStatus.GOOD;
    }

    forOwn(performance, (stat: RoomPerformanceStat) => {
      if (stat.usage > 75) {
        critical++;
      }
      if (stat.usage > 50) {
        warning++;
      }
    });

    if (critical === 1 || warning === 2) {
      id = PerformanceStatus.AVERAGE;
    }
    if (critical > 1 || warning > 2) {
      id = PerformanceStatus.POOR;
    }
    return PERFORMANCE_STATUS_DATA.find((status) => {
      return status.id === id;
    }) as PerformanceStatusData;
  },*/
  statusDataByValue(value: number): PerformanceStatusData {
    let id = PerformanceStatus.NA;
    if (value > 75) {
      id = PerformanceStatus.POOR;
    } else if (value > 50) {
      id = PerformanceStatus.AVERAGE;
    } else if (value >= 0) {
      id = PerformanceStatus.GOOD;
    }
    return PERFORMANCE_STATUS_DATA.find((status) => {
      return status.id === id;
    }) as PerformanceStatusData;
  },
  _old_buildDatasets(
    performanceChartData: Array<PerformanceChartRawData>
  ): Array<PerformanceChartFormattedData> {
    performanceChartData = reverse(performanceChartData);

    const cpu = [] as Array<number>;
    const memory = [] as Array<number>;
    const disk = [] as Array<number>;
    const gpu = [] as Array<number>;
    const network = [] as Array<number>;

    let cpuProcesses = [] as Array<string>;
    let memoryProcesses = [] as Array<string>;
    let diskProcesses = [] as Array<string>;
    let gpuProcesses = [] as Array<string>;
    let networkProcesses = [] as Array<string>;

    const labels: Array<string> = []; // tutti i timestamp

    if (performanceChartData.length > 0) {
      performanceChartData.forEach((data: PerformanceChartRawData) => {
        labels.push(DateTime.fromMillis(data.date).toFormat("dd LLL hh:mm"));
        cpu.push(formatValue(data.values[0]));
        memory.push(formatValue(data.values[1]));
        disk.push(formatValue(data.values[2]));
        gpu.push(formatValue(data.values[3]));
        network.push(formatValue(data.values[4]));

        cpuProcesses = data.processes[0];
        memoryProcesses = data.processes[1];
        diskProcesses = data.processes[2];
        gpuProcesses = data.processes[3];
        networkProcesses = data.processes[4];
      });
    } else {
      labels.push(DateTime.fromMillis(Date.now()).toFormat("dd LLL hh:mm"));
      cpu.push(0);
      memory.push(0);
      disk.push(0);
      gpu.push(0);
      network.push(0);
    }

    return [
      {
        labels: labels,
        datasets: [
          {
            label: i18n.t("common.cpu") as string,
            data: cpu,
            backgroundColor: CHART_PALETTES[0].backgrounds[0],
            borderColor: CHART_PALETTES[0].borders[0],
            borderWidth: 1.4,
          },
        ],
        processes: cpuProcesses,
      },
      {
        labels: labels,
        datasets: [
          {
            label: i18n.t("common.memory") as string,
            data: memory,
            backgroundColor: CHART_PALETTES[1].backgrounds[1],
            borderColor: CHART_PALETTES[1].borders[1],
            borderWidth: 1.4,
          },
        ],
        processes: memoryProcesses,
      },
      {
        labels: labels,
        datasets: [
          {
            label: i18n.t("common.disk") as string,
            data: disk,
            backgroundColor: CHART_PALETTES[0].backgrounds[1],
            borderColor: CHART_PALETTES[0].borders[1],
            borderWidth: 1.4,
          },
        ],
        processes: diskProcesses,
      },
      {
        labels: labels,
        datasets: [
          {
            label: i18n.t("common.gpu") as string,
            data: gpu,
            backgroundColor: CHART_PALETTES[2].backgrounds[1],
            borderColor: CHART_PALETTES[2].borders[1],
            borderWidth: 1.4,
          },
        ],
        processes: gpuProcesses,
      },
      {
        labels: labels,
        datasets: [
          {
            label: "network",
            data: network,
            backgroundColor: CHART_PALETTES[2].backgrounds[4],
            borderColor: CHART_PALETTES[2].borders[4],
            borderWidth: 1.4,
          },
        ],
        processes: networkProcesses,
      },
    ];
  },
  buildDatasets(
    performanceChartData: Array<PerformanceChartRawData>
  ): ChartData {
    performanceChartData = reverse(performanceChartData);

    const cpu = [] as Array<number>;
    const memory = [] as Array<number>;
    const gpu = [] as Array<number>;
    const disk = [] as Array<number>;

    const cpuProcesses = [] as Array<Array<string>>;
    const memoryProcesses = [] as Array<Array<string>>;
    const gpuProcesses = [] as Array<Array<string>>;
    const diskProcesses = [] as Array<Array<string>>;

    const labels: Array<string> = []; // tutti i timestamp

    if (performanceChartData.length > 0) {
      performanceChartData.forEach((data: PerformanceChartRawData) => {
        labels.push(DateTime.fromMillis(data.date).toFormat("dd LLL hh:mm"));
        cpu.push(formatValue(data.values[0]));
        memory.push(formatValue(data.values[1]));
        gpu.push(formatValue(data.values[3]));
        disk.push(formatValue(data.values[2]));

        cpuProcesses.push(data.processes[0]);
        memoryProcesses.push(data.processes[1]);
        gpuProcesses.push(data.processes[3]);
        diskProcesses.push(data.processes[2]);
      });
    } else {
      labels.push(DateTime.fromMillis(Date.now()).toFormat("dd LLL hh:mm"));
      cpu.push(0);
      memory.push(0);
      gpu.push(0);
      disk.push(0);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: i18n.t("common.cpu") as string,
          data: cpu,
          backgroundColor: CHART_PALETTES[3].backgrounds[3],
          borderColor: CHART_PALETTES[3].borders[3],
          borderWidth: 1.4,
          processes: cpuProcesses,
          fill: false,
        },
        {
          label: i18n.t("common.memory") as string,
          data: memory,
          backgroundColor: CHART_PALETTES[2].backgrounds[2],
          borderColor: CHART_PALETTES[2].borders[2],
          borderWidth: 1.4,
          processes: memoryProcesses,
          fill: false,
        },
        {
          label: i18n.t("common.gpu") as string,
          data: gpu,
          backgroundColor: CHART_PALETTES[0].backgrounds[1],
          borderColor: CHART_PALETTES[0].borders[1],
          borderWidth: 1.4,
          processes: gpuProcesses,
          fill: false,
        },
        {
          label: i18n.t("common.disk") as string,
          data: disk,
          backgroundColor: CHART_PALETTES[3].backgrounds[1],
          borderColor: CHART_PALETTES[3].borders[1],
          borderWidth: 1.4,
          processes: diskProcesses,
          fill: false,
        },
      ],
    };
  },
};
