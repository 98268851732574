
































































































import Vue, { VueConstructor } from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import validationMixin, {
  ValidatableForm,
} from "@/components/mixins/validationMixin";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import { emptyPlaylist, Playlist, playlistConfig } from "@/models/playlist";
import VaCard from "@/components/molecules/VaCard.vue";
import VaTextArea from "@/components/atoms/VaTextArea.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import cloneDeep from "lodash/cloneDeep";

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof dataTableMixin> &
      InstanceType<typeof validationMixin>
  >
).extend({
  name: "SignagePlaylists",
  mixins: [validationMixin, dataTableMixin],
  components: {
    VaButton,
    VaTextArea,
    VaTextField,
    VaCard,
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      storeName: "playlist",
      tableConfig: playlistConfig,
      currentPlaylist: cloneDeep(emptyPlaylist),
    };
  },
  beforeMount() {
    this.$store.dispatch("media/fetchAll");
  },
  methods: {
    onCreate(): void {
      this.$store.commit("playlist/resetCurrentPlaylist");
      this.dialog = true;
      this.$nextTick(() => {
        (this.$refs.form as ValidatableForm).resetValidation();
      });
    },
    onSave(): void {
      if ((this.$refs.form as ValidatableForm).validate()) {
        this.$store.dispatch("playlist/createPlaylist", this.currentPlaylist);
      }
    },
  },
  computed: {
    playlists(): Playlist[] {
      return this.$store.getters["playlist/playlists"];
    },
    dialog: {
      get(): boolean {
        return this.$store.getters["playlist/editing"];
      },
      set(dialog: boolean): void {
        this.$store.commit("playlist/setEditing", dialog);
      },
    },
  },
});
