import { User } from "@/models/user";

export type AllowedRoles = "admin" | "dsAdmin";

export const rolesDecoder = {
  admin: "Company IT Admin",
  dsAdmin: "Company Playlist Admin",
} as { [key: string]: string };

export const isAllowed = (
  user: User,
  requiredRoles: AllowedRoles[]
): boolean => {
  return requiredRoles.some((r: AllowedRoles) => {
    return user?.roles.includes(rolesDecoder[r]);
  });
};
