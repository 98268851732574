var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.playlists,
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["playlist/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["playlist/loading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["playlist/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push(
              "/digital-signage/playlists/" + item.playlistId
            )
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("ds.playlists"),
                    cta: _vm.$t("ds.playlists")
                  },
                  on: { ctaClick: _vm.onCreate },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.creationDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("date2string")(item.creationDate)) + " "
                )
              ]
            }
          },
          {
            key: "item.updateDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("date2string")(item.updateDate)) + " "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-details-button", {
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/digital-signage/playlists/" + item.playlistId
                      )
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "va-dialog", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "va-card",
            [
              _c("v-overlay", {
                attrs: {
                  absolute: true,
                  value: _vm.$store.getters["playlist/loading"] == true
                }
              }),
              _c("div", { staticClass: "va-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      !_vm.update
                        ? _vm.$t("ds.newPlaylist")
                        : _vm.$t("ds.editPlaylist")
                    ) +
                    " "
                )
              ]),
              _vm.currentPlaylist
                ? _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: {
                        "lazy-validation": "",
                        onSubmit: "return false;"
                      },
                      on: { submit: _vm.onSave },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("va-text-field", {
                        staticClass: "mb-4",
                        attrs: {
                          rules: [_vm.required],
                          label: _vm.$t("common.name")
                        },
                        model: {
                          value: _vm.currentPlaylist.name,
                          callback: function($$v) {
                            _vm.$set(_vm.currentPlaylist, "name", $$v)
                          },
                          expression: "currentPlaylist.name"
                        }
                      }),
                      _c("va-text-area", {
                        staticClass: "mb-4",
                        attrs: { label: _vm.$t("common.description") },
                        model: {
                          value: _vm.currentPlaylist.description,
                          callback: function($$v) {
                            _vm.$set(_vm.currentPlaylist, "description", $$v)
                          },
                          expression: "currentPlaylist.description"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("va-button", {
                            staticClass: "mr-4 ml-auto",
                            attrs: {
                              color: "secondary",
                              type: "outline",
                              loading: _vm.$store.getters["media/loading"],
                              cta: _vm.$t("common.dismiss")
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          }),
                          _c("va-button", {
                            attrs: {
                              submit: "",
                              disabled: !_vm.valid,
                              loading: _vm.$store.getters["media/loading"],
                              cta: _vm.$t("common.submit")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }