































































































































































































































































































import Vue from "vue";
import {
  RoomCommandParameterTypes,
  RoomCommandScript,
  RoomDetail,
} from "@/models/room";
import RoomService from "@/services/roomService";
import { RoomCommandTypes } from "@/models/room";
import FileUtil from "@/utils/fileUtil";
import FileUploader from "@/components/shared/FileUploader.vue";
import { FileFormatted } from "@/models/file";
import VaCard from "@/components/molecules/VaCard.vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { Tag } from "@/models/tag";
import { Group } from "@/models/group";
import { Building } from "@/models/building";
import clone from "lodash/clone";
import MaintenanceSwitch from "@/components/organisms/rooms/MaintenanceSwitch.vue";

export default Vue.extend({
  name: "RoomRemoteManagement",
  components: {
    MaintenanceSwitch,
    VaButton,
    VaTextField,
    VaSwitch,
    VaCard,
    FileUploader,
  },
  data() {
    return {
      sending: false,
      // name: "",
      // microphoneLevel: 50,
      // volumeLevel: 50,
      downloading: false,
      polling: 0,
      script: null as unknown as FileFormatted,
      scriptRunAsSystem: false,
      // capacity: 0,
      // capacityNotificationEnabled: false,
      // peopleCountEnabled: false,
      // tags: [] as Array<Tag>,
      // selectedTags: [] as Array<Tag>,
      updatingTags: false,
      updatingGroup: false,
      // group: {} as Group,
      updatingBuilding: false,
    };
  },
  beforeMount() {
    this.$store.dispatch("building/getBuildings");
    this.$store.dispatch("group/fetchAll");
    this.$store.dispatch("tag/fetchList");
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    async sendCommand(
      command: RoomCommandTypes,
      parameter: RoomCommandParameterTypes
    ): Promise<void> {
      this.sending = true;
      try {
        await RoomService.sendCommand(command, parameter);
        this.$notification.success(this.$t("roomCommands.commandSuccess"));
        if (command === RoomCommandTypes.SCRIPT) {
          (
            this.$refs.scriptUploader as Vue & { removeFile: () => void }
          ).removeFile();
          this.scriptRunAsSystem = false;
        }
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomRemoteManagement", "sendCommand");
      } finally {
        this.sending = false;
      }
    },
    togglePeoplecount() {
      console.log(this.room.roomCapacity);
      if (this.room.roomCapacity) {
        if (
          this.room.roomCapacity.peopleCount &&
          !this.room.roomCapacity?.capacity
        ) {
          this.room.roomCapacity.capacity = 1;
        }
        if (!this.room.roomCapacity.peopleCount && this.room.roomCapacity) {
          this.room.roomCapacity.capacity = 0;
          this.room.roomCapacity.showNotification = false;
        }
      }
    },
    setName(): void {
      this.sendCommand(RoomCommandTypes.MACHINE_NAME, this.room.name);
    },
    setCapacity(): void {
      this.sendCommand(RoomCommandTypes.ROOM_CAPACITY, {
        peopleCount: this.room.roomCapacity?.peopleCount || false,
        capacity: Number(this.room.roomCapacity?.capacity || 0),
        showNotification: this.room.roomCapacity?.showNotification || false,
      });
    },
    setStatus(status: boolean): void {
      const command = status ? RoomCommandTypes.LAUNCH : RoomCommandTypes.STOP;
      this.sendCommand(command, null);
    },
    setMicrophone(status: boolean): void {
      this.sendCommand(RoomCommandTypes.MICROPHONE, status);
    },
    setVolume(status: boolean): void {
      this.sendCommand(RoomCommandTypes.VOLUME, status);
    },
    setMicrophoneLevel(): void {
      if (this.room.microphoneLevel !== undefined) {
        this.sendCommand(
          RoomCommandTypes.MICROPHONE_LEVEL,
          this.room.microphoneLevel
        );
      }
    },
    setVolumeLevel(): void {
      this.sendCommand(RoomCommandTypes.VOLUME_LEVEL, this.room.volumeLevel);
    },
    restartMachine(): void {
      this.sendCommand(RoomCommandTypes.RESTART_MACHINE, null);
    },
    restartAirServer(): void {
      this.sendCommand(RoomCommandTypes.RESTART_AIRSERVER, null);
    },
    uploadScript(script: FileFormatted | null) {
      this.script = script as FileFormatted;
    },
    setScript(): void {
      this.sendCommand(RoomCommandTypes.SCRIPT, {
        fileId: (this.script as FileFormatted).id as string,
        fileName: (this.script as FileFormatted).name as string,
        runAsSystem: this.scriptRunAsSystem,
      } as RoomCommandScript);
    },
    async stopDownload() {
      clearInterval(this.polling);
      this.downloading = false;
      this.$notification.warning(this.$t("common.downloadAborted"));
    },
    async downloadLog() {
      this.downloading = true;
      try {
        const commandId = await RoomService.sendCommand(RoomCommandTypes.LOG);
        this.polling = setInterval(async () => {
          const res = await RoomService.getCommand(this.room.id, commandId);
          if (res.status === 6) {
            clearInterval(this.polling);
            FileUtil.downloadByResource(
              res.response,
              (this.$store.getters["room/room"] as RoomDetail).id + "_log",
              "zip"
            );
            this.$notification.success(this.$t("roomCommands.logSuccess"));
            this.downloading = false;
          }
        }, 1500);
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomRemoteManagement", "downloadLog");
        this.downloading = false;
      }
    },
    // async updateMaintenance(enabled: boolean) {
    //   // this.updatingTags = true;
    //   try {
    //     const room = await RoomService.patch(this.room.id, {
    //       isMaintenanceModeEnabled: enabled,
    //     });
    //     this.$store.commit("room/setRoom", room);
    //     this.$notification.success(
    //       enabled
    //         ? this.$t("roomInformation.maintenanceModeON")
    //         : this.$t("roomInformation.maintenanceModeOFF")
    //     );
    //   } catch (e) {
    //     this.$notification.error(e);
    //     this.$sentry.capture(e, "RoomInformation", "updateTags");
    //   } finally {
    //     // this.updatingTags = false;
    //   }
    // },
    async updateTags(tags: Tag[]) {
      this.updatingTags = true;
      try {
        const room = await RoomService.patch(this.room.id, {
          tags: tags.map((tag: Tag) => tag.id),
        });
        this.$store.commit("room/setRoom", room);
        this.$notification.success(this.$t("roomInformation.tagsSuccess"));
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomInformation", "updateTags");
      } finally {
        this.updatingTags = false;
      }
    },
    async updateGroup(group: Group) {
      this.updatingGroup = true;
      try {
        const room = await RoomService.patch(this.room.id, {
          groupId: group.id,
        });
        this.$store.commit("room/setRoom", room);
        this.$notification.success(this.$t("roomInformation.groupSuccess"));
      } catch (e) {
        this.$notification.error(e);
        this.$sentry.capture(e, "RoomInformation", "updateGroup");
      } finally {
        this.updatingGroup = false;
      }
    },
  },
  computed: {
    tags(): Tag[] {
      return this.$store.getters["tag/tags"];
    },
    // isMaintenanceModeEnabled: {
    //   get(): boolean {
    //     const room = this.$store.getters["room/room"];
    //     return room?.isMaintenanceModeEnabled || false;
    //   },
    //   set(manteinanceMode: boolean) {
    //     this.updateMaintenance(manteinanceMode);
    //   },
    // },
    selectedTags: {
      get(): Tag[] {
        const room = this.$store.getters["room/room"];
        return room?.tags || [];
      },
      set(tags: Tag[]) {
        this.updateTags(tags);
      },
    },
    group: {
      get(): Group {
        const room = this.$store.getters["room/room"];
        return room?.group;
      },
      set(group: Group) {
        this.updateGroup(group);
      },
    },
    // capacityNotificationEnabled(): boolean {
    //   const room = this.$store.getters["room/room"];
    //   return room?.roomCapacity?.showNotification || false;
    // },
    // peopleCountEnabled(): boolean {
    //   const room = this.$store.getters["room/room"];
    //   return room?.roomCapacity?.peopleCount || false;
    // },
    room(): RoomDetail {
      return clone(this.$store.getters["room/room"]);
    },
    groups(): Array<Group> {
      // abacab
      return this.$store.getters["group/availableGroups"];
    },
    buildings(): Building[] {
      return this.$store.getters["room/buildings"];
    },
    selectedBuilding: {
      get() {
        return this.$store.getters["room/room"].buildingId;
      },
      async set(value: Building) {
        this.updatingBuilding = true;
        try {
          await RoomService.patch(this.$store.getters["room/room"].id, {
            buildingId: value.uuid,
          });
          this.$notification.success(
            this.$t("roomInformation.buildingSuccess")
          );
        } catch (e) {
          this.$notification.error(e);
          this.$sentry.capture(e, "RoomInformation", "updateBuilding");
        } finally {
          this.updatingBuilding = false;
        }
      },
    },
  },
});
